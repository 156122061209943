import { memo, SVGProps } from 'react';

const SvgIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 11 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.93175 14.6896C7.3425 12.9237 10.56 8.64365 10.56 6.23958C10.56 3.32389 8.195 0.958334 5.28 0.958334C2.365 0.958334 1.19209e-07 3.32389 1.19209e-07 6.23958C1.19209e-07 8.64365 3.2175 12.9237 4.62825 14.6896C4.9665 15.1104 5.5935 15.1104 5.93175 14.6896ZM5.28 4.47917C5.74678 4.47917 6.19444 4.66464 6.52451 4.99478C6.85457 5.32492 7.04 5.77269 7.04 6.23958C7.04 6.70648 6.85457 7.15424 6.52451 7.48439C6.19444 7.81453 5.74678 8 5.28 8C4.81322 8 4.36556 7.81453 4.03549 7.48439C3.70543 7.15424 3.52 6.70648 3.52 6.23958C3.52 5.77269 3.70543 5.32492 4.03549 4.99478C4.36556 4.66464 4.81322 4.47917 5.28 4.47917Z'
      fill='#F59E0B'
    />
  </svg>
);

const Memo = memo(SvgIcon2);
export { Memo as SvgIcon2 };
