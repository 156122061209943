import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const AdminPage = () => {
    const [cartSubmissions, setCartSubmissions] = useState([]);
    const userRole = localStorage.getItem("role");
    const navigate = useNavigate();
    const backend_url = process.env.REACT_APP_API_BASE_URL;

    // Fetch cart submissions
    useEffect(() => {
        if (userRole !== "superuser") navigate("/");
        fetchCartSubmissions();
    }, [userRole]);

    const fetchCartSubmissions = async () => {
        toast.loading("Fetching cart submissions...");
        try {
            const response = await fetch(`${backend_url}/api/cart/cart-submissions`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch cart submissions");
            }

            const data = await response.json();
            setCartSubmissions(data.cart_submissions);
            toast.dismiss(); // Dismiss loading toast
            toast.success("Cart submissions fetched successfully!");
        } catch (error) {
            console.error("Error fetching cart submissions:", error);
            toast.dismiss(); // Dismiss loading toast
            toast.error("Failed to fetch cart submissions");
        }
    };

    const calculatePrice = async (submissionId) => {
        toast.loading("Calculating price...");
        try {
            const response = await fetch(`${backend_url}/api/cart/calculate-price/${submissionId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to calculate price");
            }

            const { total_price } = await response.json();
            toast.dismiss(); // Dismiss loading toast
            toast.success(`Price calculated: $${total_price}`);
        } catch (error) {
            console.error("Error calculating price:", error);
            toast.dismiss(); // Dismiss loading toast
            toast.error("Failed to calculate price");
        }
    };

    const notifyUser = async (submissionId, quotedPrice) => {
        toast.loading("Notifying user...");
        try {
            const response = await fetch(`${backend_url}/api/cart/quote-price/${submissionId}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
                body: JSON.stringify({ quoted_price: quotedPrice }),
            });

            if (!response.ok) {
                throw new Error("Failed to notify user");
            }

            const data = await response.json();
            toast.dismiss(); // Dismiss loading toast
            toast.success(data.message);
            fetchCartSubmissions(); // Refresh the list
        } catch (error) {
            console.error("Error notifying user:", error);
            toast.dismiss(); // Dismiss loading toast
            toast.error("Failed to notify user");
        }
    };

    return (
        <div className="p-6">
            <Toaster /> {/* Toast container for notifications */}
            <h1 className="text-2xl font-bold mb-6">Admin Dashboard</h1>

            {/* Role-Based Sections */}
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                {userRole === "superuser" && (
                    <div className="bg-white shadow-md rounded-lg p-4">
                        <h2 className="text-xl font-semibold mb-4">Manage Users</h2>
                        <p className="text-gray-600">View, edit, or delete user accounts.</p>
                        <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                            Go to Users
                        </button>
                    </div>
                )}

                <div className="bg-white shadow-md rounded-lg p-4">
                    <h2 className="text-xl font-semibold mb-4">Manage Products</h2>
                    <p className="text-gray-600">Add, update, or remove products.</p>
                    <button className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">
                        Go to Products
                    </button>
                </div>

                {userRole === "superuser" && (
                    <div className="bg-white shadow-md rounded-lg p-4">
                        <h2 className="text-xl font-semibold mb-4">Site Analytics</h2>
                        <p className="text-gray-600">View site performance and metrics.</p>
                        <button className="mt-4 px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600">
                            View Analytics
                        </button>
                    </div>
                )}
            </div>

            {/* Cart Submissions Section */}
            {userRole === "superuser" && (
                <div className="mt-8">
                    <h2 className="text-xl font-bold mb-4">Cart Submissions</h2>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border border-gray-200">
                            <thead>
                                <tr className="bg-gray-100">
                                    <th className="px-4 py-2 border text-left">ID</th>
                                    <th className="px-4 py-2 border text-left">User</th>
                                    <th className="px-4 py-2 border text-left">Status</th>
                                    <th className="px-4 py-2 border text-left">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cartSubmissions.map((submission) => (
                                    <tr key={submission.id} className="hover:bg-gray-50">
                                        <td className="px-4 py-2 border">{submission.id}</td>
                                        <td className="px-4 py-2 border">{submission.user.name}</td>
                                        <td className="px-4 py-2 border capitalize">{submission.status}</td>
                                        <td className="px-4 py-2 border">
                                            <button
                                                onClick={() => calculatePrice(submission.id)}
                                                className="mr-2 px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                                            >
                                                Calculate Price
                                            </button>
                                            <button
                                                onClick={() => notifyUser(submission.id, 100)} // Replace 100 with dynamic total_price
                                                className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                                            >
                                                Notify User
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminPage;