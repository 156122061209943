export const activity_list = [
    {
        "uuid": "98833d2f-af29-44e7-ba37-3b9b924eed3f",
        "activity_name": "How Steady is your hand?",
        "grade": "VI",
        "chapter": "12. ELECTRICITY & CIRCUITS",
        "concept": "A fun game based on a basic circuit",
        "topic": "Circuit",
        "quarter": 3.0,
        "type": "G",
        "aidance": "Moderate",
        "space": "Minimal",
        "super_topic": "Electricity"
    },
    {
        "uuid": "97aceb96-fc2b-4078-8a37-a78ca9b1ad32",
        "activity_name": "Simple Electric Circuit and its components",
        "grade": "VI",
        "chapter": "12. ELECTRICITY & CIRCUITS",
        "concept": "Components of a basic Circuit",
        "topic": "Circuit",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "949a6388-17a4-4756-91e3-f0d5ebc0a32e",
        "activity_name": "Visible effect of current",
        "grade": "VII",
        "chapter": "14. ELECTRIC CURRENT AND ITS EFFECT",
        "concept": "Observing the effect of current flowing through a circuit.",
        "topic": "Effect of current",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "9ef306df-db3e-44be-9d2a-dd36ad2d6809",
        "activity_name": "Sources of current: Li ion cell",
        "grade": "VI",
        "chapter": "12. ELECTRICITY & CIRCUITS",
        "concept": "Measuring the voltage across the source(Dry cell/Li Ion Cell) using a voltmeter.",
        "topic": "EMF Sources",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "b1082476-b794-40c8-bae3-2de91b11995a",
        "activity_name": "Sources of current: Lemon/potatoS",
        "grade": "VI",
        "chapter": "12. ELECTRICITY & CIRCUITS",
        "concept": "Quantitative Study of lemon/potato as sources of EMF by connecting them with multimeter.",
        "topic": "EMF Sources",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "2a5d9110-5f7f-4463-86c7-fd7d5f3bc37a",
        "activity_name": "Sources of current: Electrolyte solution",
        "grade": "VI",
        "chapter": "12. ELECTRICITY & CIRCUITS",
        "concept": "Quantitative study of salt water as a source of EMF by dipping electrodes in it and connecting them with voltmeter.",
        "topic": "EMF Sources",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "84f63dce-3e22-4513-bc2e-54da63af309b",
        "activity_name": "Sources of current: Hand generator gun",
        "grade": "VI",
        "chapter": "12. ELECTRICITY & CIRCUITS",
        "concept": "Qualitative study of  Induced EMF due to EMI phenomena using hand generator apparatus.",
        "topic": "EMF Sources",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "48f80d5c-ddb6-464a-9a1c-6d7561d0af77",
        "activity_name": "EMF Sources: Solar cell",
        "grade": "XII",
        "chapter": "3. CURRENT ELECTRICITY",
        "concept": "Qualitative study of solar cell as a source of EMF.",
        "topic": "EMF Sources",
        "quarter": 1.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "ce91cdc6-f027-4b3a-b5ac-084f4c770d78",
        "activity_name": "EMF Sources: Thermoelectricity",
        "grade": "XII",
        "chapter": "3. CURRENT ELECTRICITY",
        "concept": "Qualitative study of thermo-EMF produced in a thermocouple due to thermoelectricity.",
        "topic": "EMF Sources",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "77b9af41-06ae-47ea-9eaa-7b265af77cf2",
        "activity_name": "Sources of current: Hand battery",
        "grade": "VI",
        "chapter": "12. ELECTRICITY & CIRCUITS",
        "concept": "The naturally-occurring electrolytes found on the skin to produce a potential difference",
        "topic": "EMF Sources",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "0ccd8ffb-5db9-434c-97c3-2f885e759f42",
        "activity_name": "Polarity check of a Li ion cell",
        "grade": "VI",
        "chapter": "12. ELECTRICITY & CIRCUITS",
        "concept": "Checking the polarity of an EMF source, by checking  the direction of flow of current using a diode in the circuit.",
        "topic": "Current electricity & Ohm's law",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "3d8c5075-1f21-4aa3-9ebc-f6e311e7dd77",
        "activity_name": "Change in direction of the current due to changing polarity using Galvanometer",
        "grade": "VII",
        "chapter": "14. ELECTRIC CURRENT AND ITS EFFECT",
        "concept": "Checking the polarity of an EMF source, by checking  the direction of flow of current using an  ammeter in the circuit.",
        "topic": "Current electricity & Ohm's law",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "c8f74837-40ad-4de5-8222-14231b2322ca",
        "activity_name": "Effect of stacking batteries with bulb, motor & current measurement",
        "grade": "VII",
        "chapter": "14. ELECTRIC CURRENT AND ITS EFFECT",
        "concept": "Quantitative study of the effect on the current and  voltage by connecting  batteries  in series and parallel with a load using an ammeter",
        "topic": "Current electricity & Ohm's law",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "a89f2b03-be0d-4eff-a965-35cb65bae650",
        "activity_name": "Effect of changing load(bulb, motor & household items) on current",
        "grade": "X",
        "chapter": "12. ELECTRICITY",
        "concept": "Quantitative study of the effect on the current and  voltage by connecting  resistances  in series and parallel with a load using an ammeter.",
        "topic": "Current electricity & Ohm's law",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "b9b7107f-735a-4990-9106-1d4901457f27",
        "activity_name": "Ohm's law demonstration with Power  supply unit and Ohm's law apparatus",
        "grade": "X",
        "chapter": "12. ELECTRICITY",
        "concept": "Quantitative study of the variation in the current flowing in a circuit by varying the resistance of the circuit.",
        "topic": "Current electricity & Ohm's law",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "18143c78-5f37-4e3b-b3b9-11d014940fb8",
        "activity_name": "Effect of length & cross sectional area on resistance ( R= rho L/A)",
        "grade": "X",
        "chapter": "12. ELECTRICITY",
        "concept": "Dependence of the resistance on the cross sectional area of the conductor.",
        "topic": "Current electricity & Ohm's law",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "1937c32f-2705-47bb-814a-de7701d1bb7e",
        "activity_name": "Dependence of resistance of  a semiconductor on temperature",
        "grade": "XII",
        "chapter": "3. CURRENT ELECTRICITY",
        "concept": "Variation of resistance of a semiconductor device with temperature.",
        "topic": "Current electricity & Ohm's law",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "c80f7926-4024-40c6-8a37-d0304476d809",
        "activity_name": "Dependence of resistance of  a thermistor on temperature",
        "grade": "XII",
        "chapter": "3. CURRENT ELECTRICITY",
        "concept": "Variation of resistance of a thermistor device with temperature.",
        "topic": "Current electricity & Ohm's law",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "dde7e28d-6063-4436-b699-eeaa06466a0b",
        "activity_name": "Dependence of resistance of  a metal on temperature",
        "grade": "XII",
        "chapter": "3. CURRENT ELECTRICITY",
        "concept": "Variation of resistance of a metal with temperature.",
        "topic": "Current electricity & Ohm's law",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "c90515e0-292e-4886-8a75-2adf49de76bf",
        "activity_name": "Dependence of resistance of  a non-metal on temperature-",
        "grade": "XII",
        "chapter": "3. CURRENT ELECTRICITY",
        "concept": "Variation of resistance of an amorphous non-metal with temperature.",
        "topic": "Current electricity & Ohm's law",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "9d531961-42d5-48d5-89c8-dfbc2569fd0b",
        "activity_name": "Explanation of the concept of short-circuit using bulb setup /motorized fan",
        "grade": "VII",
        "chapter": "14. ELECTRIC CURRENT AND ITS EFFECT",
        "concept": "Qualitative study of the phenomenon of short circuit using three flashlight bulbs.",
        "topic": "DC Circuit",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "b5703891-0e4d-4d87-84ba-e843fbd654c5",
        "activity_name": "Study of parallel combination of resistances",
        "grade": "X",
        "chapter": "12. ELECTRICITY",
        "concept": "Qualitative study of the effect of combining resistances in parallel.",
        "topic": "Combination of Resistances",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "5373d807-6e57-487a-8608-4afaead5a49e",
        "activity_name": "Study of series combination of resistances",
        "grade": "X",
        "chapter": "12. ELECTRICITY",
        "concept": null,
        "topic": null,
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "32c9d77f-db09-4c9a-a3c1-8610dc990525",
        "activity_name": "Current conservation in branches of split circuit (KCL & KVL)",
        "grade": "XII",
        "chapter": "3. CURRENT ELECTRICITY",
        "concept": "Qualitative study of KCL in a circuit",
        "topic": "KCL, KVL",
        "quarter": 1.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "d1e58e5b-7fda-4aa7-81d9-c2b4e0ed71a4",
        "activity_name": "DC Capacitive circuit",
        "grade": "XII",
        "chapter": "3. CURRENT ELECTRICITY",
        "concept": "Study of DC capacitive circuit",
        "topic": "DC Circuit",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "9b8fce88-6243-4df9-821e-f20419492615",
        "activity_name": "AC Capacitive circuit",
        "grade": "XII",
        "chapter": "3. CURRENT ELECTRICITY",
        "concept": "Study of AC capacitive circuit",
        "topic": "AC Circuits",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "54a2b23c-b19c-4387-963b-a04182897153",
        "activity_name": "LRC circuit",
        "grade": "XII",
        "chapter": "3. CURRENT ELECTRICITY",
        "concept": "Study of LRC capacitive circuit",
        "topic": "DC and AC Circuits",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "3fdfa688-ef68-43f5-a41c-33888c57f9b4",
        "activity_name": "Joule's law of heating with Horizontal wire holder apparatus",
        "grade": "VII",
        "chapter": "14. ELECTRIC CURRENT AND ITS EFFECT",
        "concept": "Qualitative study of Joule's law of heating using a thin nichrome wire connected to a horizontal wire holder.",
        "topic": "Resistance and Joule's heating",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "39112968-0e1e-488a-b1cc-be391bc99127",
        "activity_name": "Joule's law of heating with Y shaped wire holder apparatus",
        "grade": "X",
        "chapter": "12. ELECTRICITY",
        "concept": "Qualitative study of Joule's law of heating using a thin nichrome wire connected to a  Y shaped wire holder.",
        "topic": "Resistance and Joule's heating",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "ddd51aec-879a-47f0-ae91-0105006e26d8",
        "activity_name": "Nichrome wire as a fuse",
        "grade": "VII",
        "chapter": "14. ELECTRIC CURRENT AND ITS EFFECT",
        "concept": "Qualitative study of Joule's law of heating and its application in electrical fuse.",
        "topic": "Resistance and Joule's heating",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "0c5ab87d-e7cb-4d3d-961c-830e1e7e45ff",
        "activity_name": "Fuse in a circuit",
        "grade": "VII",
        "chapter": "14. ELECTRIC CURRENT AND ITS EFFECT",
        "concept": "Qualitative study of application of electrical fuse in a circuit.",
        "topic": "Resistance and Joule's heating",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "9878100b-15cf-4edc-9a1d-f3f1cc213ddc",
        "activity_name": "Joule's law of heating using graphite",
        "grade": "X",
        "chapter": "12. ELECTRICITY",
        "concept": "Qualitative study of Joule's law of heating using a graphite",
        "topic": "Resistance and Joule's heating",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "e0c97b76-083d-4866-9731-da14bf3a4e5c",
        "activity_name": "Pencil circuit",
        "grade": "VI",
        "chapter": "12. ELECTRICITY & CIRCUITS",
        "concept": "Study of graphite as a conductor",
        "topic": "Non-metal conductor",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "71ab452a-da50-4984-858d-f51df310d7f0",
        "activity_name": "Hot wire ammeter",
        "grade": "XII",
        "chapter": "3. CURRENT ELECTRICITY",
        "concept": "Application of Joule's law of heating using a hot wire ameter.",
        "topic": "Resistance and Joule's heating",
        "quarter": 1.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "1ca94995-49c5-43ba-b933-af7ad001673e",
        "activity_name": "Electrolysis of H2O - 1",
        "grade": "VIII",
        "chapter": "14. CHEMICAL EFFECTS OF ELECTRIC CURRENT",
        "concept": "An experiment demonstrating Electrolysis of water",
        "topic": "Electrolysis of water",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "7e51d981-b19e-46cc-adf9-24a4aba1cc46",
        "activity_name": "Electrolysis of H2O - 2",
        "grade": "VIII",
        "chapter": "14. CHEMICAL EFFECTS OF ELECTRIC CURRENT",
        "concept": "An experiment demonstrating Electrolysis of water",
        "topic": "Electrolysis of water",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "ad9fb695-f5d5-47bf-aea0-978271e6a639",
        "activity_name": "Electroplating of Cu",
        "grade": "VIII",
        "chapter": "14. CHEMICAL EFFECTS OF ELECTRIC CURRENT",
        "concept": "An experiment demonstrating electroplating of Copper on another metal.",
        "topic": "Copper Plating",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electricity"
    },
    {
        "uuid": "8108a5d6-73c8-40ce-b59c-91a722a3918a",
        "activity_name": "Concept of location, distance and Measurement",
        "grade": "VI",
        "chapter": "10. Motion and Measurement of distances",
        "concept": "An experiment demonstrating the concept of position and distance",
        "topic": "Position and distance",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "12b2d8df-cdbf-419a-9bcc-a29fc82a07a2",
        "activity_name": "Measurement of length",
        "grade": "VI",
        "chapter": "10. Motion and Measurement of distances",
        "concept": "An experiment demonstrating the concept of measurement",
        "topic": "Measurement of length",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "e6a35bea-95f6-4567-ae1b-0ef71816ba34",
        "activity_name": "Concept of speed",
        "grade": "VII",
        "chapter": "13. MOTION AND TIME",
        "concept": "An experiment demonstrating the concept of motion, speed, average speed and uniform motion",
        "topic": "Concept of speed, average speed and uniform motion",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "d7a23587-8840-451b-b52f-e1bde1bf2d04",
        "activity_name": "Uniform Speed: Drifter with motionshot",
        "grade": "IX",
        "chapter": "8. MOTION",
        "concept": "Analyze the uniform motion of a drifter by observing the distance travelled by drifter in equal time intervals using motion shot software.",
        "topic": "Uniform Motion",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "e2de6adc-ec15-44d1-a1ca-95131e21e2a3",
        "activity_name": "Uniform Speed: UM car(Measurement)",
        "grade": "VII",
        "chapter": "13. MOTION AND TIME",
        "concept": "Measure the Uniform speed of the UM car by measuring the distance travelled by the UM car using the meter tape in equal intervals of time (measured using the stopwatch).",
        "topic": "Uniform Motion",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "a7a436cb-895c-4ff5-8e2b-e6598cc8b4e1",
        "activity_name": "Uniform Speed: Free car and motorized rotating disc",
        "grade": "IX",
        "chapter": "8. MOTION",
        "concept": "Measure the Uniform speed of the free car by measuring the number of rotations made by the disc in equal intervals of time (measured using the stopwatch).",
        "topic": "Uniform motion measurement",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "e129b7be-25c6-4abb-b0f3-2e62dbec232a",
        "activity_name": "Uniform Speed: UM car and Ticker tape",
        "grade": "IX",
        "chapter": "8. MOTION",
        "concept": "Measure the Uniform speed of the UM car by measuring the length of the ink mark an knowing the frequency of the ticker.",
        "topic": "Uniform motion measurement",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "c9ce9f53-bb66-41d8-a0f5-5a142f3f68d0",
        "activity_name": "Uniform Speed: Mechanical Bench with FizziQ app",
        "grade": "XI",
        "chapter": "3. MOTION IN A STRAIGHT LINE",
        "concept": "Measure the Uniform speed of the slider of the Mechanical bench by taking the video of the slider in motion an analyzing the video using the FizziQ app.",
        "topic": "Uniform motion measurement",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "29d29003-0cdf-4aa0-9f55-8aee00dd0355",
        "activity_name": "Uniform Speed: UM car and sensors",
        "grade": "XI",
        "chapter": "3. MOTION IN A STRAIGHT LINE",
        "concept": "Measure the Uniform speed of the UM car by measuring the distance travelled by the UM car using the meter scale in equal intervals of time, taken from the photogate sensor.",
        "topic": "Uniform motion measurement",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "2c3be8c7-ca41-4f91-99ff-926011e47fe0",
        "activity_name": "Uniform Speed: Chase problem with UM car",
        "grade": "XI",
        "chapter": "3. MOTION IN A STRAIGHT LINE",
        "concept": "Qualitative analysis of the chase problem using two UM cars.",
        "topic": "Relative motion",
        "quarter": 1.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "350b94bd-8859-4a25-8a66-7b9dccde97da",
        "activity_name": "Accelerated motion: NM car with motionshot",
        "grade": "IX",
        "chapter": "8. MOTION",
        "concept": "Analyze the accelerated motion of a NM car by observing the distance travelled by NM car in equal time intervals using motion shot software.",
        "topic": "non-uniform motion",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "5c062984-0966-48e7-a72f-66cfda516b04",
        "activity_name": "Accelerated motion: Free fall of a ball with FizziQ app",
        "grade": "IX",
        "chapter": "8. MOTION",
        "concept": "Analyze the accelerated motion of a freely falling ball by observing the distance travelled by the ball in equal time intervals using FizziQ app.",
        "topic": "Non-uniform motion measurement",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "bb8a5fb8-b6e1-4ea7-8fff-ed6f65f07573",
        "activity_name": "Accelerated motion: Ball on an inclined plane",
        "grade": "IX",
        "chapter": "8. MOTION",
        "concept": "Analyze the accelerated motion of a ball on an inclined plane by observing the distance travelled by the ball in equal time intervals using FizziQ app.",
        "topic": "Non-uniform motion measurement",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "4efa0424-1d89-40b7-a422-47ffdd67bd3c",
        "activity_name": "Accelerated motion: NM cart slider on Mechanical Bench with FizziQ app",
        "grade": "XI",
        "chapter": "3. MOTION IN A STRAIGHT LINE",
        "concept": "Quantitative study of rectilinear non-uniform motion(Constant acceleration) of an NM car on the mechanical bench using the FizziQ app.",
        "topic": "Non-uniform motion measurement",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "e1a52fe6-6f7d-407c-bd98-dce9ce0c835d",
        "activity_name": "Chase problem with UM and NM car",
        "grade": "XI",
        "chapter": "3. MOTION IN A STRAIGHT LINE",
        "concept": "Qualitative study of chase problem in rectilinear motion using UM  and NM cars",
        "topic": "Relative motion",
        "quarter": 1.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "ddbb6ea1-d34a-41e3-8e72-8f7c52dfbaa0",
        "activity_name": "Relative Motion: Conveyor setup  an UM car",
        "grade": "XI",
        "chapter": "3. MOTION IN A STRAIGHT LINE",
        "concept": "Qualitative study of relative velocity using conveyor setup  an UM car",
        "topic": "Relative Velocity",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "89e867a6-fb46-4a28-844a-1de0d498dc38",
        "activity_name": "Relative Motion: UM cars",
        "grade": "IX",
        "chapter": "8. MOTION",
        "concept": "Quantitative study of relative velocity using  UM cars",
        "topic": "Relative Velocity",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "dfcb7586-1bdf-47f1-a94f-ad9e39e11819",
        "activity_name": "Relative Motion: UM car with 4 different speeds",
        "grade": "XI",
        "chapter": "8. MOTION",
        "concept": "Quantitative study of relative velocity using  UM cars",
        "topic": "Relative Velocity",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "1-D Motion"
    },
    {
        "uuid": "ed88c212-42ae-41c1-8f12-a94d7003e855",
        "activity_name": "2-D Motion: Hanging display",
        "grade": "XI",
        "chapter": "4. MOTION IN A PLANE",
        "concept": "Qualitative analysis of the motion of a ball under gravity using the hanging display an ejector. The focus must be given to the trajectory of the ball.",
        "topic": "Motion in 2D: introduction",
        "quarter": 1.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "2-D Motion"
    },
    {
        "uuid": "bd474929-3a81-456d-8f7d-d420c363523d",
        "activity_name": "2-D Motion: Cardboard, ball and colours",
        "grade": "XI",
        "chapter": "4. MOTION IN A PLANE",
        "concept": "Qualitative analysis of the trajectory of a moving ball on an inclined plane under a constant acceleration. The trajectory can be drawn by putting colours on the ball.",
        "topic": "Motion in 2D\nintroduction",
        "quarter": 1.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "2-D Motion"
    },
    {
        "uuid": "01af0f10-3701-4ef0-b38e-aa33bacc4dd6",
        "activity_name": "2-D Motion: HV apparatus",
        "grade": "XI",
        "chapter": "4. MOTION IN A PLANE",
        "concept": "Qualitative analysis of 2-D motion by considering it as two independent 1-D motion by comparing the time taken by the two balls to reach the ground from a particular height. Here, one ball has an initial horizontal velocity, the other one has zero horizontal velocity.",
        "topic": "Motion under gravity",
        "quarter": 1.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "2-D Motion"
    },
    {
        "uuid": "24955066-3e77-4cff-b9ff-a4d512665cb9",
        "activity_name": "2-D Motion: Ball projecting car",
        "grade": "XI",
        "chapter": "4. MOTION IN A PLANE",
        "concept": "Qualitative study of motion in a plane by treating it as two separate simultaneous one-dimensional motions with constant acceleration(gravity) using a Human cart with ball projector.",
        "topic": "Motion under gravity",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "2-D Motion"
    },
    {
        "uuid": "f80d2e8e-fa7e-4fb8-ba5c-720e048dade5",
        "activity_name": "2-D Motion: FOR apparatus",
        "grade": "XI",
        "chapter": "4. MOTION IN A PLANE",
        "concept": "Use of frame of reference to analyse 2-D motion using a ballistic car an a magnetic ball.",
        "topic": "Motion under gravity",
        "quarter": 1.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "2-D Motion"
    },
    {
        "uuid": "c16b7d77-2359-4a69-8cc5-e226c7d59d69",
        "activity_name": "2-D Motion: Circular motion by Drifter",
        "grade": "VI",
        "chapter": "10. MOTION AND MEASUREMENT OF DISTANCES",
        "concept": "Qualitative study of the circular motion performed by a drifter.",
        "topic": "Circular motion",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "2-D Motion"
    },
    {
        "uuid": "3a99c666-5e42-4721-a986-eae7c0b4709c",
        "activity_name": "2-D Motion: Centripetal Acceleration setup (CA setup )",
        "grade": "IX",
        "chapter": "8. MOTION",
        "concept": "Qualitative study of the circular motion performed by a steel ball in a circular track. Here the focus is to observe the tangential motion of the ball when it leaves the circular track.",
        "topic": "Circular motion",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "2-D Motion"
    },
    {
        "uuid": "8e9055c0-2d57-4c3a-aaec-1ec652ff77dd",
        "activity_name": "2-D Motion: Monkey-Gun problem",
        "grade": "XI",
        "chapter": "4. MOTION IN A PLANE",
        "concept": "Qualitative analysis of the motion of an object under a constant acceleration using monkey-gun apparatus.",
        "topic": "Projectile motion",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "2-D Motion"
    },
    {
        "uuid": "fc940ec6-4b24-4594-b194-575a3716972f",
        "activity_name": "2-D Motion: Projectile motion with Ball",
        "grade": "XI",
        "chapter": "4. MOTION IN A PLANE",
        "concept": "Quantitative study of the projectile motion performed by a ball using a projectile launcher.",
        "topic": "Projectile motion",
        "quarter": 1.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "2-D Motion"
    },
    {
        "uuid": "c0296eb9-0f62-4249-8d2c-e174babaf149",
        "activity_name": "2-D Motion: Projectile motion with Rubber Band",
        "grade": "XI",
        "chapter": "4. MOTION IN A PLANE",
        "concept": "Qualitative study of the projectile motion performed by a rubber band using a projectile launcher.",
        "topic": "Projectile motion",
        "quarter": 1.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "2-D Motion"
    },
    {
        "uuid": "df0b3b5b-a744-497b-8e2e-041143705c5a",
        "activity_name": "2-D Motion: Relative motion between UM cars",
        "grade": "XI",
        "chapter": "4. MOTION IN A PLANE",
        "concept": "Qualitative study of relative motion using 2 UM cars with camera mounted setup",
        "topic": "Relative motion",
        "quarter": 1.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "2-D Motion"
    },
    {
        "uuid": "53640f25-75c3-40ef-bbbe-d7b43afea3ca",
        "activity_name": "2-D Motion: River-Boat problem",
        "grade": "XI",
        "chapter": "4. MOTION IN A PLANE",
        "concept": "Quantitative study of the river-boat problem using an UM car an an conveyor belt setup . Analogous to the river boat problem, here the conveyor setup  will be considered as the river and the UM car as the boat.",
        "topic": "Relative motion",
        "quarter": 1.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "2-D Motion"
    },
    {
        "uuid": "32c897b1-2b99-4f42-8dbb-0de72ce30cef",
        "activity_name": "Types of materials: Transparent, Transluscent and Opaque",
        "grade": "VI",
        "chapter": "4. SORTING MATERIALS INTO GROUPS",
        "concept": "Observing properties of different materials based on whether light can pass through it or not",
        "topic": "Types of material based on their optical properties",
        "quarter": 1.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "843ff655-a6cb-4d2c-b606-b203fefacabb",
        "activity_name": "Colour of Shadows",
        "grade": "VI",
        "chapter": "11. LIGHT, SHADOWS AND REFLECTIONS",
        "concept": "Formation of shadows",
        "topic": "Shadows",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "98a6650c-96dc-4fa0-956e-ff65bdd854d0",
        "activity_name": "Pinhole Camera",
        "grade": "VI",
        "chapter": "11. LIGHT, SHADOWS AND REFLECTIONS",
        "concept": "Application of rectilinear propagation of light",
        "topic": "Pinhole camera",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "39975888-e3c9-4789-b857-6bb0e4e106cb",
        "activity_name": "Rectilinear path of light",
        "grade": "VI",
        "chapter": "11. LIGHT, SHADOWS AND REFLECTIONS",
        "concept": "Study of propagation of light",
        "topic": "Linear Propagation of Light",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "6077c448-bbb0-4a63-b2d7-fa1a3ea5c628",
        "activity_name": "Reflection on a plane mirror",
        "grade": "VI",
        "chapter": "11. LIGHT, SHADOWS AND REFLECTIONS",
        "concept": "Observing the phenomenon of reflection of a ray of light on a plane surface using a plane mirror, single ray torch and pencil.",
        "topic": "Reflection in a plane mirror",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "93d71ffe-cc89-478d-bc0e-112b6f6b02d1",
        "activity_name": "Periscope",
        "grade": "VI",
        "chapter": "11. LIGHT, SHADOWS AND REFLECTIONS",
        "concept": "Application of rectilinear propagation of light",
        "topic": "Reflection in a plane mirror",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "fba59c5e-f694-4197-9548-4a3c73d6fdfb",
        "activity_name": "Image distance = object distance",
        "grade": "VII",
        "chapter": "15. LIGHT",
        "concept": "Formation of image in a plane mirror and its properties",
        "topic": "Reflection in a plane mirror",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "99834aca-605e-44d1-9a56-056d2b2981e1",
        "activity_name": "Right or Left",
        "grade": "VII",
        "chapter": "15. LIGHT",
        "concept": "Formation of image in a plane mirror and its properties",
        "topic": "Reflection in a plane mirror",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "f09bf6bf-e776-459e-b965-b85f1fcf5210",
        "activity_name": "Laws of reflection test",
        "grade": "VIII",
        "chapter": "16. LIGHT",
        "concept": "Verifying the laws of reflection using protractor setup . Focus on the angle of incidence and angle of reflection",
        "topic": "Reflection in a plane mirror",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "099e4137-fd13-46d5-b9ad-81ffda2a094b",
        "activity_name": "Image tracing(Ray tracer method)",
        "grade": "VII",
        "chapter": "15. LIGHT",
        "concept": "Use of cardboard pins, single ray torch an a pencil to trace the image position in a plane mirror.",
        "topic": "Reflection in a plane mirror",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "beda66be-9236-45b6-93bb-cda132800cca",
        "activity_name": "Image tracing(Parallax method)",
        "grade": "VII",
        "chapter": "15. LIGHT",
        "concept": "Placing a pencil behind the plane mirror at an approximate position which will coincide with the image of a pencil placed in front  of the mirror as an object.",
        "topic": "Reflection in a plane mirror",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "93835a5e-b67f-4c4b-acaa-386094a37056",
        "activity_name": "Image distance measurement(Ray tracer method)",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Use of cardboard pins, single ray torch an a pencil to trace the image position an measure the image distance in a plane mirror.",
        "topic": "Reflection in a plane mirror",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "09290141-6a85-4171-b15c-5396670c3c7d",
        "activity_name": "Image position(single ray torch method)",
        "grade": "VII",
        "chapter": "15. LIGHT",
        "concept": "Use of cardboard pins, single ray torch an a pencil to locate the image position in a plane mirror.",
        "topic": "Reflection in a plane mirror",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "1c288e52-c11c-4364-9037-590118932681",
        "activity_name": "Plane mirror rotation",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Rotate the plane mirror by a particular angle to see how much the reflected ray rotates.",
        "topic": "Reflection in a plane mirror",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "f25de5f8-a54e-46ce-9538-6fe6b42d77a3",
        "activity_name": "Multiple image formation in plane mirror",
        "grade": "VIII",
        "chapter": "16. LIGHT",
        "concept": "Find out the number of images formed by two plane mirrors placed at an particular angle.",
        "topic": "Reflection in a plane mirror",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "dc499e3f-6322-4793-8dff-f182172b8d97",
        "activity_name": "Infinity mirror 1",
        "grade": "VIII",
        "chapter": "16. LIGHT",
        "concept": "Find out the number of images of an object by two parallel plane mirrors, when the object is placed between them.",
        "topic": "Reflection in a plane mirror",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "654fd668-c6e1-4080-b58d-b9e67183011e",
        "activity_name": "Infinity mirror 2",
        "grade": "VIII",
        "chapter": "16. LIGHT",
        "concept": "Number of images formed in an kaleidoscope",
        "topic": "Reflection in a plane mirror",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "72bcd456-89ab-4707-875e-b8cad80ad469",
        "activity_name": "Plane Mirror Optical illusion",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Two large L shaped mirrors arranged parallelly to cloak the space in between them, illustrating an optical, unidirectional cloaking mechanism for objects.",
        "topic": "Reflection in a plane mirror",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "0a69fabd-08d1-4106-9581-be90c696fdd2",
        "activity_name": "Concave Mirror: Image formation",
        "grade": "VII",
        "chapter": "15. LIGHT",
        "concept": "This demonstration features a device that uses a concave mirror to project the image of a small light bulb into an empty light socket. While it is possible to hide the source of the projected light from the viewers, we have found it better to allow students to easily see the source of the hidden light for a better understanding of the behavior of light rays reflected by a concave mirror.",
        "topic": "Reflection in a spherical surface",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "bc130af3-e3ba-4704-8e79-db8eb1eee8d8",
        "activity_name": "Concave Mirror: Classroom Mirror with laser",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Visualization of focal point of large mirrors using parallel source of light(laser beam) and spray.",
        "topic": "Reflection in a spherical surface",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "7104ca5f-81b2-4aee-b3f2-ecf715b0fe86",
        "activity_name": "Concave Mirror: Classroom Mirror with sun rays",
        "grade": "VII",
        "chapter": "15. LIGHT",
        "concept": "Visualization of focal point of large mirrors using sun rays an spray.",
        "topic": "Reflection in a spherical surface",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "98663559-c885-40cf-852a-94e8fc3496f5",
        "activity_name": "Concave Mirror: Classroom Mirror with flashlight",
        "grade": "VII",
        "chapter": "15. LIGHT",
        "concept": "Visualization of focal point of large mirrors using flashlight and spray.",
        "topic": "Reflection in a spherical surface",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "542104a2-5a0d-4806-b10c-9970305630ac",
        "activity_name": "Spherical Mirrors: Analogue Model",
        "grade": "VII",
        "chapter": "15. LIGHT",
        "concept": "Pencils attached to the reflective sheets will act as rays of light",
        "topic": "Reflection in a spherical surface",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "48c2bc94-dae6-4303-b840-8f867386ca0b",
        "activity_name": "Concave Mirror from plane mirrors",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Making a spherical mirror from small plane mirrors.",
        "topic": "Reflection in a spherical surface",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "b810354a-2874-4d32-bc00-5c918f81ab2b",
        "activity_name": "Concave Mirror: Image formation using an arrow source",
        "grade": "VII",
        "chapter": "15. LIGHT",
        "concept": "Observing the phenomenon of formation of image of an LED arrow source by curved mirrors using a concave/convex mirror, arrow source and screen.",
        "topic": "Reflection in a spherical surface",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "e7b94507-1323-44f4-af18-607656121e9f",
        "activity_name": "Convex Mirror: Image formation using an arrow source",
        "grade": "VII",
        "chapter": "15. LIGHT",
        "concept": "Observing the phenomenon of formation of image of an LED arrow source by curved mirrors using a concave/convex mirror, arrow source and screen.",
        "topic": "Reflection in a spherical surface",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "e3e5235b-c8d5-4dd5-b23e-529cd5816f36",
        "activity_name": "Concave mirror: Ray diagram using single ray torch",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Making ray diagrams of a concave mirror for objects at different positions",
        "topic": "Reflection in a spherical surface",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "db9baf2c-4b25-44c1-bd52-01391402b55c",
        "activity_name": "Spherical mirror: Mirror formula and magnification of spherical mirrors",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Verification of mirror formula and magnification using concave and convex mirrors",
        "topic": "Reflection in a spherical surface",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "9f9a8c8d-650a-402d-981c-e83997e7d7cf",
        "activity_name": "Field of View for different mirrors",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Compare the field of views of different mirrors",
        "topic": "Field of view",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "975f5163-e139-4622-b8f9-8e06780be799",
        "activity_name": "Optical illusion using parabolic mirrors",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "This demonstration features a device that uses two parabolic mirrors to form an image",
        "topic": "Reflection in a spherical surface",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "49f4e90e-29fd-4eab-8d37-f964536c5849",
        "activity_name": "Concave Mirror Magic",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "This demonstration features a device that uses a concave mirror to project the image of a small light bulb into an empty light socket. While it is possible to hide the source of the projected light from the viewers, we have found it better to allow students to easily see the source of the hidden light for a better understanding of the behavior of light rays reflected by a concave mirror.",
        "topic": "Reflection in a spherical surface",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "96b1cc54-4e07-4e45-97b4-ef49f9bcaec6",
        "activity_name": "Spherical aberration & Parabolic Mirror",
        "grade": "XII",
        "chapter": "9. RAY OPTICS",
        "concept": "1. Observe spherical aberration in concave mirror.\n2. Observe the absence of spherical aberration in parabolic mirror",
        "topic": "Reflection in a spherical surface",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "346c8acd-4e38-427d-b6a8-a1fef840318e",
        "activity_name": "Refraction: Refraction tank with laser",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Visualisation of the phenomena of refraction of a laser light going from air to water",
        "topic": "Refraction",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "cd64109a-4de3-450b-b46b-ad03971a2562",
        "activity_name": "Refraction: Image of pencil in water",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Visualisation of the phenomena of refraction in water  using a pencil.",
        "topic": "Refraction",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "925f4d76-6092-430c-855b-63e8bcebb030",
        "activity_name": "Refraction: Vanishing coin",
        "grade": "XII",
        "chapter": "9. RAY OPTICS",
        "concept": "This experiment is a type of illusion where a coin placed below the glass of water will not be visible when we pour water in it",
        "topic": "Refraction",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "981ec962-8ead-43ed-8f84-f54a57812e7f",
        "activity_name": "Deviation and Dispersion in glass slab",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Visualisation of phenomena of deviation and dispersion of light when passing through a glass slab",
        "topic": "Refraction",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "a01b7184-5495-4157-917d-1c79a86f1c0a",
        "activity_name": "Snell's law: Total Internal Reflection in Refraction tank",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "A device to demonstrate Snell's law and critical angle. Also, explore refraction and total internal reflection in a water tank with the help of scattering agent and laser rays.",
        "topic": "Refraction",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "9f57f1fc-780f-49e6-8c9d-cf77258f4999",
        "activity_name": "Snell's law: Total Internal Reflection in Snell's law apparatus",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "A device to demonstrate Snell's law and critical angle. Also, explore refraction and total internal reflection in a water tank with the help of scattering agent and laser rays.",
        "topic": "Refraction",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "fa3fb96b-806a-4814-bd34-6b3ea4667516",
        "activity_name": "Vanishing glassware",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Application of refraction through water",
        "topic": "Refraction",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "6ccb9df3-d9be-4b5e-9577-1daa63e702e5",
        "activity_name": "Homemade prism",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Refraction through different liquid medium",
        "topic": "Refraction through a prism",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "0d6aba47-dccc-4848-8b13-677bdc091252",
        "activity_name": "Rainbow formation",
        "grade": "VII",
        "chapter": "15. LIGHT",
        "concept": "Study of Dispersion in prism",
        "topic": "Refraction through a prism",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "497aef05-018e-45eb-9665-bb9741e8ed17",
        "activity_name": "Angle of deviation measurement",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Measuring angle of Deviation in prism",
        "topic": "Refraction through a prism",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "7feb9b89-73d7-4671-987c-a0a4e56b454f",
        "activity_name": "Deviation and Dispersion measurement in Protractor based Prism",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Measuring angle of Deviation in prism",
        "topic": "Refraction through a prism",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "8adfbd95-a9b6-4cf2-9a38-6dd7fc759ca0",
        "activity_name": "Lens: Refraction by lens",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Refraction through a Lens",
        "topic": "Refraction through a spherical surface",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "d3fb3ec7-bede-4afc-918e-f38ac5ee8c76",
        "activity_name": "Lens: focal point observation",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Observing focal point of a Lens",
        "topic": "Refraction through a spherical surface",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "2439eae8-d07c-4988-a4c6-ea06debad671",
        "activity_name": "Lens: Image formation using Arrow source",
        "grade": "VII",
        "chapter": "15. LIGHT",
        "concept": "Studying the properties of an image formed by a lens and relationship between object distance and image distance",
        "topic": "Refraction through a spherical surface",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "ae84790d-44be-4b07-ae81-312332663d30",
        "activity_name": "Lens: Optical bench",
        "grade": "XII",
        "chapter": "9. RAY OPTICS",
        "concept": "Studying the properties of an image formed by a lens and relationship between object distance and image distance",
        "topic": "Refraction through a spherical surface",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "16ab933f-4fcb-4ae9-80b1-51ff6749c02b",
        "activity_name": "Lens: Biconvex lens focal point in different medium",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Variation of focal point of a lens in different medium",
        "topic": "Refraction through a spherical surface",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "77b88f49-b093-4dd3-8720-0d75ce172693",
        "activity_name": "Lens: Diverging lens",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Variation of focal point of a lens in different medium",
        "topic": "Refraction through a spherical surface",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "5852b2a9-bf00-4d76-bfbf-39147c602450",
        "activity_name": "Mirrors: Fun with curved mirrors",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Study of image formed in an irregualr curved surface.",
        "topic": "Refraction through a spherical surface",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "9f35a15b-037d-4eeb-ad92-0151cad37be8",
        "activity_name": "Lens: Combination of a concave and convex lens of same Power",
        "grade": "X",
        "chapter": "10. LIGHT \u2013 REFLECTION AND REFRACTION",
        "concept": "Study of power of a combination of different lenses",
        "topic": "Refraction through a spherical surface",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "e8259b66-1a79-4d66-ad2b-a4fb22bc3a06",
        "activity_name": "Elliptical carrom",
        "grade": "XII",
        "chapter": "9. RAY OPTICS",
        "concept": "Study of parabolic mirror.",
        "topic": "Reflection at a Elliptical surface & Fermat's principle.",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "50eda3b3-b929-4647-8371-592e291cc340",
        "activity_name": "Young's double slit experiment",
        "grade": "XII",
        "chapter": "10. Wave optics",
        "concept": "Observing interference patteren produced in Young's Double Slit Experiment",
        "topic": "Interference",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "e2a34416-8faa-43fb-9c16-a55d657951f0",
        "activity_name": "Single slit diffraction using shaving blades",
        "grade": "XII",
        "chapter": "10. Wave optics",
        "concept": "Study of diffraction of light by a narrow slit",
        "topic": "Diffraction",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Optics"
    },
    {
        "uuid": "3fdeabcd-eb2c-4fb2-b17d-1bf359168afe",
        "activity_name": "Types of forces: Push/Pull",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Qualitative study of push/pull as a force",
        "topic": "Types of Forces",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "415f98b7-8972-4ab5-9cbf-c911b2b1c8a0",
        "activity_name": "Types of forces: Friction",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE\n12. Friction",
        "concept": "Qualitative study of friction as a force",
        "topic": "Types of Forces",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "756d6dd2-07a2-4cc5-a73b-63610afceb9a",
        "activity_name": "Types of forces: Tension",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Qualitative study of tension as a force",
        "topic": "Types of Forces",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "2552a347-3f57-469a-94a2-f7f1e289a111",
        "activity_name": "Types of forces: Normal reaction",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Quantitative study of normal reaction",
        "topic": "Types of Forces",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "356a40d6-7570-4ef2-96fb-a64e7e565366",
        "activity_name": "Types of forces: Spring force",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Quantitative study of spring force",
        "topic": "Types of Forces",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "90fd3139-beac-4d84-ae15-79cf53eb20fd",
        "activity_name": "Types of forces: Buoyant force",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Quantitative study of buoyant force",
        "topic": "Types of Forces",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "a1e4d0dc-eb8c-46f5-b4cf-1c3a56261233",
        "activity_name": "Types of forces: Air drag(Parachute)",
        "grade": "VIII",
        "chapter": "12. FRICTION",
        "concept": "Qualitative study of air drag force using parachute",
        "topic": "Types of Forces",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "d7c2df60-6042-4023-a09e-5d57b3af54c7",
        "activity_name": "Types of forces: Paper toys",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Qualitative study of air drag force using paper",
        "topic": "Types of Forces",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "03e3f997-82bf-47f8-b3ac-bb8c5c225567",
        "activity_name": "Types of forces: Gravity",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Qualitative study of Gravitation",
        "topic": "Types of Forces",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "0b672179-143d-470f-ba22-26dffdcc4d46",
        "activity_name": "Types of forces: Potato Gun",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Qualitative study of force due to air pressure",
        "topic": "Types of Forces",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "a34a0c27-b1ae-4a56-9a50-7f2118255cee",
        "activity_name": "Balanced and unbalanced forces: External/Internal forces",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Qualitative study of external and internal forces",
        "topic": "Balanced and unbalanced forces",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "52779adf-4997-4fd6-9f08-d0e1998b94c4",
        "activity_name": "Balanced and unbalanced forces: Human cart",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Qualitative study of external and internal forces",
        "topic": "Balanced and unbalanced forces",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "1d7d9fe4-59e2-4d7b-a98a-130d6a5f0338",
        "activity_name": "Balanced and unbalanced forces: Drifter",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Qualitative study of balanced and unbalanced forces using a drifter",
        "topic": "Balanced and unbalanced forces",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "c524f843-24e7-4422-84a2-b328de5af478",
        "activity_name": "Balanced and unbalanced forces: Spring balance",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Quantitative study of spring force and its measurement",
        "topic": "Balanced and unbalanced forces",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "d4235a2a-3666-4f1b-870a-f62450915251",
        "activity_name": "Balanced and unbalanced forces: Strongest man challenge",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Qualitative study of balanced and unbalanced forces in \"strongest man challenge\"",
        "topic": "Balanced and unbalanced forces",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "e6c91748-53f0-4a09-890a-ccfc129581e2",
        "activity_name": "Balanced and unbalanced forces: Inclined plane force demonstrator",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Quantitative study of balanced and unbalanced forces of an object placed on an inclined plane",
        "topic": "Balanced and unbalanced forces",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "c3f05b6f-93cd-4517-b90a-22fca69b762d",
        "activity_name": "Balanced and unbalanced forces: Atwood machine",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Quantitative study of balanced and unbalanced forces using atwood machine",
        "topic": "Balanced and unbalanced forces",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "ab1b0842-61e0-4209-9343-a4eec1897d6c",
        "activity_name": "Balanced and unbalanced forces: Equilibrium of forces",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Demonstration of balanced forces using weights with ratio 3:4:5",
        "topic": "Balanced and unbalanced forces",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "c6bfb4d0-aa72-42c5-b0f7-6d6df047dc98",
        "activity_name": "Balanced and unbalanced forces: Holding a pendulum at right angle",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Demonstration of balanced forces using a block and thread.",
        "topic": "Balanced and unbalanced forces",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "d26409eb-0a53-487e-990b-b03d15920045",
        "activity_name": "Inertia: Inertia tracks",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Demonstration of  Newton's 1st law of motion(inertia of motion) using Galileo's inertia track.",
        "topic": "Newton's 1st Law: Inertia",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "4ef97e47-4875-4c91-83ed-60607aff4c75",
        "activity_name": "Inertia: Glass and cloth",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of inertia of rest using a cloth an a glass container glass full of water",
        "topic": "Newton's 1st Law: Inertia",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "316d88f6-372d-4de4-98bf-98c502d1979e",
        "activity_name": "Inertia: Snapping of threads",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of inertia of rest using threads of different materials an a weight.",
        "topic": "Newton's 1st Law: Inertia",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "cafce8e3-d7ab-4d60-96c5-76c36d040ec4",
        "activity_name": "Inertia: Hoop and coin",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of inertia of rest using a hoop, beaker and coin",
        "topic": "Newton's 1st Law: Inertia",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "9288f7a2-8df6-44f0-b84b-77a49a30b043",
        "activity_name": "Inertia: Inertia stick",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of inertia of rest and motion using a inertia stick an a potato",
        "topic": "Newton's 1st Law: Inertia",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "b3d3731a-521b-4c8b-8ec4-5b052152fb10",
        "activity_name": "Inertia: Inertia car",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of inertia of rest and motion using a inertia car.",
        "topic": "Newton's 1st Law: Inertia",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "83a79db7-4676-40e7-b7fb-a9594e03861b",
        "activity_name": "Inertia: Drifter",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of inertia of rest and motion using a drifter",
        "topic": "Newton's 1st Law: Inertia",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "309f15e9-7f85-4b07-a750-043d4d0fa694",
        "activity_name": "Conservation of Linear momentum: Walking board",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of Conservation of linear momentum using walking board",
        "topic": "Conservation of linear momentum",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "c5a35634-eb5e-457f-9ec4-5277ccc5e04c",
        "activity_name": "Inertia: NLM blocks",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of inertia of rest and motion using NLM blocks",
        "topic": "Newton's 1st Law: Inertia",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "aeed804c-33db-4283-8bc7-2bf454736a21",
        "activity_name": "Inertia: NLM blocks, same force, different mass",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of inertia of rest and motion using NLM blocks of different masses an same applied force",
        "topic": "Newton's 2nd Law",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "9ae551d6-7651-4249-ae2f-e7f3c77ccdd0",
        "activity_name": "Inertia: NLM blocks, same mass, different force",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of inertia of rest and motion using NLM blocks of same  mass and different applied forces",
        "topic": "Newton's 2nd Law",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "96440a11-c7b7-453b-b2cb-0998ad78d67c",
        "activity_name": "Newton's Second Law: NLM carts, same mass, different force",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of Newton's second law of motion motion using NLM cart an different applied forces",
        "topic": "Newton's 2nd Law",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "36128699-908b-4914-ae90-1e66cbc97a7d",
        "activity_name": "Newton's Second Law: NLM carts, same force, different mass",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of Newton's second law of motion  using NLM cart an same applied force",
        "topic": "Newton's 2nd Law",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "3ba1f572-90b9-4014-8dbf-16a4d30f354d",
        "activity_name": "Newton's Second Law: Human cart and Push",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of Newton's second law of motion(push) using Human cart",
        "topic": "Newton's 2nd Law",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "eaf03717-be16-4ed4-a80c-2abf84745182",
        "activity_name": "Newton's Second Law: Human cart and Pull",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of Newton's second law of motion(pull) using Human cart",
        "topic": "Newton's 2nd Law",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "d3936917-499d-4371-8cfa-3dae23845796",
        "activity_name": "Newton's Second Law: Mechanical bench and NM slider",
        "grade": "XI",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of Newton's second law of motion using mechanical bench an slider with fan",
        "topic": "Newton's 2nd Law",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "5eb00a56-320f-4b14-a264-3727da565c48",
        "activity_name": "Newton's Second Law: Mechanical bench, Slider and Pulley",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Demonstration of Newton's second law of motion using pulley attached mechanical bench.",
        "topic": "Newton's 2nd Law",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "3ac720fc-5f9d-4cc2-97b4-9f6c9d0d91b2",
        "activity_name": "Newton's Second Law: NM car",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Demonstration of Newton's second law of motion using NM car on the ground",
        "topic": "Newton's 2nd Law",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "5c03d168-53a3-4eff-88e2-463dd6a3e88b",
        "activity_name": "Newton's Second Law: NM car with flat sail",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Demonstration of Newton's second law of motion using NM car on the ground with flat sail",
        "topic": "Newton's 2nd Law",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "a5915cde-4c78-4f4d-9d50-6c2b03716915",
        "activity_name": "Newton's Second Law: NM car with curved sail",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Demonstration of Newton's second law of motion using NM car on the ground with flat sail",
        "topic": "Newton's 2nd Law",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "9d5e0b67-0583-4c01-9ce6-1fdf7b1139bd",
        "activity_name": "Newton's Second Law: NM car with flat sail on inclined plane",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Demonstration of Newton's second law of motion using NM car on an inclined plane with flat sail",
        "topic": "Newton's 2nd Law",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "f9ecc6f9-cfeb-4e3b-8b42-f2af12f1e73f",
        "activity_name": "Newton's Second Law: NM cart with flat sail",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Demonstration of Newton's second law of motion(lateral force)  using NM car on water surface with flat sail",
        "topic": "Newton's 2nd Law",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "4e0ba14f-cacc-4c08-b58c-302155e403c8",
        "activity_name": "Newton's Third Law:  Wagon-pulley setup",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Demonstration of Newton's second law of motion using wagon pulley setup",
        "topic": "Newton's 3rd Law of motion",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "04329cca-cc51-4677-8332-08e38f12385d",
        "activity_name": "Newton's Third Law: Human cart and weighing machine",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of Newton's third law of motion using a Human cart and weighing machine",
        "topic": "Force",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "37314d04-731c-4661-a5a1-3ad3eaa101c4",
        "activity_name": "Newton's Third Law: Hovercraft",
        "grade": "XI",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of Newton's third law of motion using hover craft",
        "topic": "Force",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "fbeab178-53ce-4de8-9c3c-f84133fb847f",
        "activity_name": "Newton's Third Law:  Blowing pipes",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of Newton's third law of motion using blowing pipes",
        "topic": "Force",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "967246a5-4db0-42c1-aeac-a3e42db853bf",
        "activity_name": "Newton's Third Law: Bottle spinner",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of Newton's third law of motion using bottle spinner",
        "topic": "Force",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "24fc1458-e472-4a4d-be76-a6a7b6a38925",
        "activity_name": "Newton's Third Law: Frictional block",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of Newton's third law of motion using frictional block",
        "topic": "Newton's 3rd law of motion",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "0c19449f-4136-40a8-b77a-4d3bbc99c50c",
        "activity_name": "Impulse demonstration with cloth",
        "grade": "XI",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of Impulse using different objects with cloth",
        "topic": "Impulse",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "17fcac38-6c87-4f2b-9612-981781f15fc8",
        "activity_name": "Impulse demonstration with drifter",
        "grade": "XI",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of Impulse using a drifter",
        "topic": "Impulse",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "1638ebb8-5357-4fea-adab-1ab40d1da01c",
        "activity_name": "Impulse demonstration with dart guns",
        "grade": "XI",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of Impulse using dart gun",
        "topic": "Impulse",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "c773011a-0910-4ece-8c3f-4f2e83f1d985",
        "activity_name": "Conservation of Linear momentum with NLM blocks",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of conservation of linear momentum using  NLM blocks",
        "topic": "Conservation of linear momentum",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "9a9bd038-6159-4c48-87e5-5e86d0816903",
        "activity_name": "Conservation of linear momentum:  One sided cannon",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of conservation of linear momentum using one side open Tennis ball cannon",
        "topic": "Conservation of linear momentum",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "8e3b3c7e-95fb-4ef5-aae8-71be13420462",
        "activity_name": "Conservation of linear momentum:  Two sided cannon",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of conservation of linear momentum using  two side open Tennis ball cannon",
        "topic": "Conservation of linear momentum",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "ee5edf47-57b4-4e30-9ad4-db4bf76ee775",
        "activity_name": "Conservation of linear momentum:  Bottle car",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of conservation of linear momentum using bottle car",
        "topic": "Conservation of linear momentum",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "a082d010-7a59-4b9e-a1b4-28c6e7de7355",
        "activity_name": "Conservation of linear momentum:  Human cart/Hovercraft",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of conservation of linear momentum using Human Cart/Hovercraft",
        "topic": "Conservation of linear momentum",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "ddc5f5c3-9893-450c-9312-b0b3b11b0a16",
        "activity_name": "Conservation of linear momentum:  Air/water rocket",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of conservation of linear momentum using air/water rocket",
        "topic": "Conservation of linear momentum",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "c7edf577-92cc-4e0b-8d3d-0d7beba32233",
        "activity_name": "Conservation of linear momentum: Balloon rocket",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of conservation of linear momentum using  balloon rocket on string",
        "topic": "Conservation of linear momentum",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "a00c37f6-718e-4c0e-b769-3140680331ee",
        "activity_name": "Conservation of linear momentum:Human carts",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of conservation of linear momentum using Human cart",
        "topic": "Conservation of linear momentum",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "51280a9e-a502-462c-b5ea-142fbbdd9335",
        "activity_name": "Conservation of linear momentum: Mechanical bench",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Demonstration of conservation of linear momentum using  mechanical bench setup",
        "topic": "Conservation of linear momentum",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "1f7a97bc-2702-4cb5-8077-00a0aed315bb",
        "activity_name": "Conservation of linear momentum: cannon and NLM car",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Demonstration of conservation of linear momentum using recoil gun/cannon",
        "topic": "Conservation of linear momentum",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "17f6f4ec-6633-4817-b3bb-9954ffb923be",
        "activity_name": "Newton's Laws of Motion: Atwood machine",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Quantitative study of Newton's laws of motion using atwood machine",
        "topic": "Application of NLM and Pulleys",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "49498b5a-028d-47ce-b75b-3395475feec9",
        "activity_name": "Newton's Laws of Motion:Multiple pulleys",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Qualitative study of Newton's laws of motion using multiple pulleys",
        "topic": "Application of NLM and Pulleys",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "692741c1-725a-4300-9352-566647443cc9",
        "activity_name": "Newton's Laws of Motion:Human pulley",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Qualitative study of Newton's laws of motion using Human pulley",
        "topic": "Application of NLM and Pulleys",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "de3698fa-7469-4f3c-bc96-40810d3bf0ab",
        "activity_name": "Lifting vs pulling",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Quantitative study of lifting vs pulling using different weights",
        "topic": "Application of NLM and Pulleys",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "8e473061-1e08-49cc-b114-b610f2f5eabd",
        "activity_name": "Problem situations",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Study of Absolute dependent motion",
        "topic": "Absolute dependent motion",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "4d3c9628-f9e5-4acf-9d3a-e050ecac38b6",
        "activity_name": "Spring paradox",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Fun with springs",
        "topic": "Miscilaneous",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "6e4fd162-26d5-418e-be27-04e4599db036",
        "activity_name": "Balloon Helicopter",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Fun with balloons",
        "topic": "Miscilaneous",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "036301d5-2e8a-42de-bc9f-07ee10f4d5bf",
        "activity_name": "Coefficient of static friction",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Study of angle of repose",
        "topic": "Friction",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "a349e7b4-6731-49ba-ab16-edff496a01df",
        "activity_name": "Angle of repose",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Study of angle of repose",
        "topic": "Friction",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "73a1cb80-6a37-4dfe-af04-12696991aa89",
        "activity_name": "Pulleys 2",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Study of Absolute dependent motion",
        "topic": "Absolute dependent motion",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "b871e696-98f0-4d65-9802-80f8aa6434e1",
        "activity_name": "Momentum Transfer: Happy ball & Sad Ball",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": null,
        "topic": "Momentum Transfer",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "7237bafd-c6a7-49b1-bca7-406a9a0b550b",
        "activity_name": "Demonstration of friction with bottle and rice",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE\n12. Friction",
        "concept": null,
        "topic": "Friction",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "a4a4d448-d43c-4edd-8d93-65d94a1eacc2",
        "activity_name": "Friction books",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE\n12. Friction",
        "concept": null,
        "topic": "Friction",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "0be40fa1-f465-45eb-bdda-4827ce762894",
        "activity_name": "Direction of friction",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE\n12. Friction",
        "concept": null,
        "topic": "Friction",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "77fa5eec-2e6e-43f9-92de-eec41413aa88",
        "activity_name": "Limiting friction",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": null,
        "topic": "Friction",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "d495d704-cfff-4b64-81f6-12642e374e64",
        "activity_name": "Friction on inclined plane",
        "grade": "XI",
        "chapter": "5. LAWS OF MOTION",
        "concept": "Study of angle of repose",
        "topic": "Friction",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Newton's laws of Motion"
    },
    {
        "uuid": "d6160ece-330c-4b5c-b21b-4e759099fe5a",
        "activity_name": "Coulomb's force measurement",
        "grade": "VIII",
        "chapter": "15. SOME NATURAL PHENOMENA",
        "concept": "Quantitative study of electrostatic force",
        "topic": "Coulomb's law",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "e52cc110-5796-41c9-93b0-0469019c31f4",
        "activity_name": "Electrostatic Accelerator",
        "grade": "XII",
        "chapter": "2. ELECTROSTATIC POTENTIAL AND CAPACITANCE",
        "concept": "Demonstration of electrostatic potential by accelerating a conducting ball inside a bowl using a Van de Graaff generator",
        "topic": "Electric potential",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "e1698581-adb9-43d2-b8a0-1a16623fe2df",
        "activity_name": "Electrostatic Levitation",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of Electric field inside a parallel plate capacitor by demonstrating electrostatic levitation",
        "topic": "Electric field",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "7f6b9990-b2f7-4c2e-b6f7-563a6831aba9",
        "activity_name": "Frictional electricity",
        "grade": "VIII",
        "chapter": "15. SOME NATURAL PHENOMENA",
        "concept": "Qualitative study of frictional electricity produced in different materials based on their Triboelectric properties",
        "topic": "Triboelectricity",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "8a13420d-822e-465a-8dfa-e0809c8538f8",
        "activity_name": "Floating plates",
        "grade": "VIII",
        "chapter": "15. SOME NATURAL PHENOMENA",
        "concept": "Qualitative study of frictional electricity using Styrofoam plates",
        "topic": "Frictional electricity",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "edb0ceeb-0856-465b-b058-5e9ee4d5b789",
        "activity_name": "Can Van de Graaff and can",
        "grade": "VIII",
        "chapter": "15. SOME NATURAL PHENOMENA",
        "concept": "Qualitative study of charging by induction-conduction using homemade can Van de Graaff generator an aluminium can",
        "topic": "Charging by conduction and induction",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "e069d660-984c-4767-8bf7-c09fbc263064",
        "activity_name": "Rolling can",
        "grade": "VIII",
        "chapter": "15. SOME NATURAL PHENOMENA",
        "concept": "Qualitative study of charging by induction using a PVC pipes, aluminium can.",
        "topic": "Charging by induction",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "711cb93b-9517-4454-ae11-ac0782833809",
        "activity_name": "Bubble magic",
        "grade": "VIII",
        "chapter": "15. SOME NATURAL PHENOMENA",
        "concept": "Qualitative study of charging by induction using a PVC pipes and soap solution.",
        "topic": "Charging by induction",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "1cf15cbb-30fc-45be-82a6-c7e5d6488005",
        "activity_name": "Jumping balls",
        "grade": "VIII",
        "chapter": "15. SOME NATURAL PHENOMENA",
        "concept": "Qualitative study of charging by induction using aluminium foil balls",
        "topic": "Charging by induction",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "399fed76-ce98-43de-b8be-87e869517a73",
        "activity_name": "Bending of water",
        "grade": "VIII",
        "chapter": "15. SOME NATURAL PHENOMENA",
        "concept": "Qualitative study of charging by induction using PVC pipe an styrofoam cups",
        "topic": "Charging by induction",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "56ecf7bb-5ad3-4354-bc83-c9b2e7866feb",
        "activity_name": "Fighting Balloons",
        "grade": "VIII",
        "chapter": "15. SOME NATURAL PHENOMENA",
        "concept": "Qualitative study of charging by induction using balloons",
        "topic": "Charging by induction",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "650cbdb9-4dad-4acd-b71a-3b12601ea13d",
        "activity_name": "Homemade electroscope",
        "grade": "VIII",
        "chapter": "15. SOME NATURAL PHENOMENA",
        "concept": "Qualitative study of charging by induction using homemade electroscope",
        "topic": "Charging by induction",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "90510437-65a7-4d5f-bf71-964d30e3c142",
        "activity_name": "Flying bag",
        "grade": "VIII",
        "chapter": "15. SOME NATURAL PHENOMENA",
        "concept": "Qualitative study of charging by induction using plastic bags",
        "topic": "Charging by induction",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "02bc27ab-f9ac-4f26-8ddc-f896bd552b59",
        "activity_name": "Tinsel-Balloon magic",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of charging by conduction and induction using tinsels and conducting balloon",
        "topic": "Charging by conduction and induction",
        "quarter": 1.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "d0c75470-1683-458f-89cf-2caab72f093e",
        "activity_name": "Can Van de Graaff and wooden stick",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of charging by conduction and induction using homemade electroscope",
        "topic": "Charging by conduction and induction",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "a4a4ef68-071b-4018-ac3c-bd6bd7f1ec3a",
        "activity_name": "Can Van de Graaff and aluminium foil",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of charging by conduction and induction using can Van de Graaff generator and aluminium foil",
        "topic": "Charging by conduction and induction",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "48562965-2762-48f7-ad2a-a5bde9b45712",
        "activity_name": "Can Van de Graaff generator and conducting balloon",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of charging by conduction and induction using can Van de Graaff generator and conducting balloon",
        "topic": "Charging by conduction and induction",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "e7e564ac-8913-4af5-b985-fad8dce70eba",
        "activity_name": "Van de Graaff generator and conducting ball",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of charging by conduction and induction using Van de Graaff generator and aluminium can",
        "topic": "Charging by Conduction & Induction",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "7f721b6c-0dd0-431e-8e58-4739ddf6ae4c",
        "activity_name": "Conduction vs Induction",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of the difference between charging by conduction and induction and introduction to polarization using different materials",
        "topic": "Charging by conduction and induction",
        "quarter": 1.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "b3eb06c5-1b29-47bb-bca2-49946993eb6b",
        "activity_name": "Van de Graaff generator and Franklin Bell",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of charging by conduction and induction(Franklin bell) using a Van de Graaff generator",
        "topic": "Charging by conduction\n& induction",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "e1522217-3e31-4236-bc2a-733bd1b63c68",
        "activity_name": "Parallel plate capacitor and Franklin Bell",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of charging by conduction and induction(Franklin bell) using a parallel plate capacitor",
        "topic": "Charging by conduction\n& induction",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "0c24e063-77e7-4303-bbaf-0f27675d9b64",
        "activity_name": "Electric Field: Van de Graaff and thermocol",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of radial electric field using Van de Graaff generator",
        "topic": "Electric Field",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "03ddb663-2ad5-4652-a6ac-bbabb447f6a5",
        "activity_name": "Electric Field: Conducting cylinder",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Visualisation of electric field due to conducting wires using KMnO4 solution",
        "topic": "Electric field",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "84c2b2e4-ec01-44e9-85ff-9cc384113865",
        "activity_name": "Electric Field: Van de Graaff and tinfoil balloon",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of radial electric field using Van de Graaff generator and tinfoil balloon",
        "topic": "Electric field",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "f5c99975-0687-425a-bb98-a91e55acb6d5",
        "activity_name": "Electric Field: Infinite plane",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of electric field due to a plane conductor using a Van de Graaff generator",
        "topic": "Electric field",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "d85655a6-458f-4727-a599-61852e3d9fc9",
        "activity_name": "Cathode Ray Tube",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of motion of a charged particle inside and electric field using Cathode Ray Tube",
        "topic": "Motion of a charged particle inside an electric field",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "f234a795-8164-4d3a-a6b6-d7dd3650ad71",
        "activity_name": "Van de Graaff generator and Inducing dipole",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of the behaviour of a dipole in a radial electric field using a dipole and a Van de Graaff generator",
        "topic": "dipole in a radial field",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "c7b92250-f0a3-44b1-832f-8aa763cf90de",
        "activity_name": "Van de Graaff tricks: Corona discharge",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of corona discharge using a Van de Graaff generator and a conical electrode",
        "topic": "Corona discharge",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "9130fed1-6b72-40a6-9987-84892c7198fb",
        "activity_name": "Corona discharge: Electric wind",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of corona discharge using a Van de Graaff generator, a S shaped conductor and saw toothed aluminium disc",
        "topic": "Corona discharge",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "2d635488-b566-439a-91cb-9a785bf1448e",
        "activity_name": "Electrostatic shielding: Cu an Al net",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of electric field inside a conductor using a Van de Graaff generator and Cu Net",
        "topic": "Electric field inside a conductor",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "ce8fa8f8-0b1f-4d93-8625-438b827bad8c",
        "activity_name": "Electrostatic shielding: Aluminium Sheet",
        "grade": "XII",
        "chapter": "2. ELECTROSTATIC POTENTIAL AND CAPACITANCE",
        "concept": "Qualitative study of  electrostatic shielding using a Van de Graaff generator an a big aluminium sheet",
        "topic": "Electrostatic Shielding",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "a694454a-615a-4b05-a2d1-9e7670e9eecb",
        "activity_name": "Electrostatic shielding: Faraday's cage",
        "grade": "XII",
        "chapter": "2. ELECTROSTATIC POTENTIAL AND CAPACITANCE",
        "concept": "Qualitative study of  electrostatic shielding using a Van de Graaff generator and a Faraday's cage",
        "topic": "electrostatic shielding",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "009d817d-44b4-4892-8b2f-f84abb279c77",
        "activity_name": "Radial electric field: Plasma globe and CFL bulb",
        "grade": "XII",
        "chapter": "2. ELECTROSTATIC POTENTIAL AND CAPACITANCE",
        "concept": "Electric field visualization using plasma globe an tubelight and CFL bulb",
        "topic": "Electric field and potential",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "21f2576e-0f80-4ae2-aa38-d39b3260e749",
        "activity_name": "Radial electric field: Van de Graaff and tubelight",
        "grade": "XII",
        "chapter": "1. ELECTRIC CHARGES & FIELDS",
        "concept": "Qualitative study of electrostatic field using a tube light and Van de Graaff generator",
        "topic": "Electric field",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "96c9bbb8-20a5-4b4d-9860-e9104b94a444",
        "activity_name": "Radial electric field: Plasma globe and Oscilloscope",
        "grade": "XII",
        "chapter": "2. ELECTROSTATIC POTENTIAL AND CAPACITANCE",
        "concept": "Qualitative study of electrostatic field  and electrostatic potential using a plasma globe an Oscilloscope(Mobile app oscope or SmartScope).",
        "topic": "Electric Field & Potential",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "70fe5471-0b26-4a43-8f33-86e97182fa07",
        "activity_name": "Radial electric field: Plasma globe and Tubelight",
        "grade": "XII",
        "chapter": "2. ELECTROSTATIC POTENTIAL AND CAPACITANCE",
        "concept": "Qualitative study of electrostatic field  and electrostatic potential using a plasma globe an tube light",
        "topic": "Relation between electric potential \n& field",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "068c1546-94ee-479b-8bde-907c870c6316",
        "activity_name": "Equipotential Surface: Carbon paper",
        "grade": "XII",
        "chapter": "2. ELECTROSTATIC POTENTIAL AND CAPACITANCE",
        "concept": "Quantitative study of equipotential surface using a battery source and carbon paper",
        "topic": "Electric Potential",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "51d78792-518c-41c0-8a67-33696ac1d7a2",
        "activity_name": "Electric field inside a parallel plate capacitor.",
        "grade": "XII",
        "chapter": "2. ELECTROSTATIC POTENTIAL AND CAPACITANCE",
        "concept": "Qualitative study of electric field inside a parallel plate capacitor",
        "topic": "Capacitance",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "d28b7218-cb80-4db9-8b9e-b3927bb38739",
        "activity_name": "Metal jar capacitor with Plastic Jar as dielectric",
        "grade": "XII",
        "chapter": "2. ELECTROSTATIC POTENTIAL AND CAPACITANCE",
        "concept": "Study of the effect of a dielectric inside a capacitor using a metal jar capacitor",
        "topic": "Capacitance",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "e12e9eee-5152-4e89-b1d5-f8627b354a76",
        "activity_name": "Variable capacitor",
        "grade": "XII",
        "chapter": "2. ELECTROSTATIC POTENTIAL AND CAPACITANCE",
        "concept": "Study of a variable capacitor using a parallel plate capacitor an plexi glass slab",
        "topic": "Capacitance",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "bc2389ef-ce04-447c-a7d7-05fb51ced778",
        "activity_name": "Capacitor as a source",
        "grade": "XII",
        "chapter": "2. ELECTROSTATIC POTENTIAL AND CAPACITANCE",
        "concept": "Study of a capacitor as a source of EMF",
        "topic": "Capacitance",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electrostatics"
    },
    {
        "uuid": "720f88cc-fd25-4940-93a1-70867197a5ea",
        "activity_name": "Glass is diamagnetic",
        "grade": "XII",
        "chapter": "5. Magnetism & Matter",
        "concept": "Study of the behaviour of a diamagnetic material inside a magnetic field.",
        "topic": "Diamagnetic Substance",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Magnetism & Matter"
    },
    {
        "uuid": "d47191c5-a207-47ce-ba43-91a3a1de0ec4",
        "activity_name": "Water is diamagnetic",
        "grade": "XII",
        "chapter": "5. Magnetism & Matter",
        "concept": "Study of the behaviour of a diamagnetic material inside a magnetic field.",
        "topic": "Diamagnetic Substance",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Magnetism & Matter"
    },
    {
        "uuid": "1a1cc750-1114-40e1-ba22-6908f940dbf5",
        "activity_name": "Aluminium is paramagnetic",
        "grade": "XII",
        "chapter": "5. Magnetism & Matter",
        "concept": "Study of the behaviour of a paramagnetic material inside a magnetic field.",
        "topic": "Paramagnetic Substance",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Magnetism & Matter"
    },
    {
        "uuid": "e89a65d3-0d6a-4202-bb71-c5b79ee65435",
        "activity_name": "CuSO4 is paramagnetic",
        "grade": "XII",
        "chapter": "5. Magnetism & Matter",
        "concept": "Study of the behaviour of a paramagnetic material inside a magnetic field.",
        "topic": "Paramagnetic Substance",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Magnetism & Matter"
    },
    {
        "uuid": "1f373ae0-875e-4b63-9dab-a444e7bc21d1",
        "activity_name": "CuO is paramagnetic",
        "grade": "XII",
        "chapter": "5. Magnetism & Matter",
        "concept": "Study of the behaviour of a paramagnetic material inside a magnetic field.",
        "topic": "Paramagnetic Substance",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Magnetism & Matter"
    },
    {
        "uuid": "490b3e5d-eb04-4984-b729-474138792a18",
        "activity_name": "Gadolinium(III) oxide is paramagnetic",
        "grade": "XII",
        "chapter": "5. Magnetism & Matter",
        "concept": "Study of the behaviour of a paramagnetic material inside a magnetic field.",
        "topic": "Paramagnetic Substance",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Magnetism & Matter"
    },
    {
        "uuid": "a199ddfa-b630-4d21-aaf0-d45492f33973",
        "activity_name": "Curie point of Iron",
        "grade": "XII",
        "chapter": "5. Magnetism & Matter",
        "concept": "Observing a ferromagnetic material behaves like a diamagnetic material at high temperature",
        "topic": "Curie point",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Magnetism & Matter"
    },
    {
        "uuid": "6c48edf7-b9b1-4b49-aa12-03f22b16bd9c",
        "activity_name": "Magnetic field of earth using compass",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Visualisation of earth's magnetic field using a compass.",
        "topic": "Permanent Magnets",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "ab702b0b-908d-4c47-b93d-ea93d7227e2e",
        "activity_name": "Magnetic field of earth using needle",
        "grade": "VI",
        "chapter": "13. FUN WITH MAGNETS",
        "concept": "Visualisation of earth's magnetic field using a needle.",
        "topic": "Permanent Magnets",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "cb512a1b-c690-4ae2-9145-30a3e868f67d",
        "activity_name": "Magnetic field of a bar magnet using a needle",
        "grade": "VI",
        "chapter": "13. FUN WITH MAGNETS",
        "concept": "Visualisation of magnetic field of a bar magnet using a needle.",
        "topic": "Permanent Magnets",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "3390eccd-2326-4752-90dd-5b62dcd93f0b",
        "activity_name": "Magnetic Field of a Bar Magnet using Compass",
        "grade": "VI",
        "chapter": "13. FUN WITH MAGNETS",
        "concept": "Visualisation of magnetic field of a bar magnet using a compass.",
        "topic": "Permanent Magnets",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "e681b435-be34-4a1d-b7bc-438651525fe2",
        "activity_name": "Magnetic Field of a Bar Magnet using Iron filings",
        "grade": "VI",
        "chapter": "13. FUN WITH MAGNETS",
        "concept": "Visualization of magnetic field lines of a bar magnet using iron dust.",
        "topic": "Permanent Magnets",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "a6cb1160-e76d-431e-bf81-28327b3dc4d2",
        "activity_name": "Types of Magnet",
        "grade": "VI",
        "chapter": "13. FUN WITH MAGNETS",
        "concept": "Classifying magnets into different groups based on their magnetic properties, shape and size and occurence",
        "topic": "Classification of magnets",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "3a32cd72-20e8-4508-900a-9291ccff56b8",
        "activity_name": "Properties of Magnets",
        "grade": "VI",
        "chapter": "13. FUN WITH MAGNETS",
        "concept": "Studying the properties of a magnet ",
        "topic": "Properties of magnets",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "371b5108-9d11-4578-b1c2-da512ea0d68e",
        "activity_name": "Floating Pin",
        "grade": "VI",
        "chapter": "13. FUN WITH MAGNETS",
        "concept": "Studying the properties of a magnet ",
        "topic": "Properties of magnets",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "2cac7ad3-e54d-4c6d-911c-c7df6c1d6e84",
        "activity_name": "Oersted Experiment",
        "grade": "VII",
        "chapter": "14. ELECTRIC CURRENT AND ITS EFFECT",
        "concept": "Visualisation of magnetic field due to wire",
        "topic": "Magnetic field due to a wire",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "8831fb6b-5dfd-4ce7-9737-61b3038763f3",
        "activity_name": "Magnetic field due to a circular coil",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Visualisation of magnetic field due to a circular coil.",
        "topic": "Electromagnets",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "d2a04f82-bcd6-41ff-aede-6b0948350877",
        "activity_name": "Magnetic field due to Square coil",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Visualisation of magnetic field due to a square coil.",
        "topic": "Electromagnets",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "9c32166a-fcd0-46b6-8dbb-920fd8ed2174",
        "activity_name": "Homemade electromagnet",
        "grade": "VII",
        "chapter": "14. ELECTRIC CURRENT AND ITS EFFECT",
        "concept": "Making an electromagnet using Cu wire to observe the magnetic effects of current.",
        "topic": "Electromagnets",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "4a494684-86e9-40d9-8e0e-7932f57f3959",
        "activity_name": "Magnetic field due to Solenoid",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Visualisation of magnetic field due to the solenoid.",
        "topic": "Electromagnets",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "3bb3af89-db3d-4798-b80d-a02d757edd14",
        "activity_name": "Deflection of cathode ray",
        "grade": "XII",
        "chapter": "4. MOVING CHARGES & MAGNETISM",
        "concept": "Quantitative study of the Lorentz force on a charge particle.",
        "topic": "Lorentz force: Moving charges",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "dcbc4016-ef2c-472f-918e-4dfc40099476",
        "activity_name": "Measuring Force on a current carrying wire inside a magnetic field",
        "grade": "XII",
        "chapter": "4. MOVING CHARGES & MAGNETISM",
        "concept": "Measurement of the force on a current carrying wire in a magnetic field.",
        "topic": "Force on a current carrying conductor in a magnetic field",
        "quarter": 1.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "58c49229-0dd9-4c41-abf3-47130814546c",
        "activity_name": "Magnetic Swing with Opposite polarity",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Observing the force on a current carrying conductor inside a magnetic field.",
        "topic": "Lorentz force: Force on current carrying wire",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "7d29abb5-8223-4c94-88f3-409ee71ccf9a",
        "activity_name": "Lorentz Cube",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Visualisation of the direction of the force on a current carrying conductor inside a magnetic field.",
        "topic": "Fleming's Rule",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "66b0d204-b89f-4442-be9c-c206c9768de9",
        "activity_name": "Force between two current carrying wires showing attraction & repulsion of parallel conductors",
        "grade": "XII",
        "chapter": "4. MOVING CHARGES & MAGNETISM",
        "concept": "Observing the force between two current carrying wires.",
        "topic": "Lorentz force: Force on current carrying wire",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "44b33406-3f53-46f3-87a5-2b768aa9b3cf",
        "activity_name": "Muscle circuit",
        "grade": "XII",
        "chapter": "4. MOVING CHARGES & MAGNETISM",
        "concept": "Observing the force between current carrying loops.",
        "topic": "Lorentz force: Force on current carrying wire",
        "quarter": 1.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "e4152214-c520-460f-a3e4-6526b1090387",
        "activity_name": "Oscillating Coil 2",
        "grade": "XII",
        "chapter": "4. MOVING CHARGES & MAGNETISM",
        "concept": "Observing the force between current carrying loops.",
        "topic": "Lorentz force: Force on current carrying wire",
        "quarter": 1.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "387362a2-bc48-4053-ba38-2f35cfe180e7",
        "activity_name": "Homopolar Vibrator",
        "grade": "XII",
        "chapter": "4. MOVING CHARGES & MAGNETISM",
        "concept": "Observing the force on a current carrying coil due to a magnet.",
        "topic": "Fun with Magnets",
        "quarter": 1.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "ae117549-e2c2-40c1-939f-90537dd733bf",
        "activity_name": "EM Rail",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Demonstration of electromagnetic force on a current carrying conductor.",
        "topic": "Lorentz force: Force on current carrying wire",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "54588ece-8341-4de5-a61e-297d0f803934",
        "activity_name": "Jumping Coil",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Observing the magnetic effect of current due to which the coil is forced to jump back and forth.",
        "topic": "Lorentz force: Force on current carrying wire",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "e71ce311-656a-48a6-9f68-9cd82f64743a",
        "activity_name": "EM Car",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Observing the magnetic effect of current due to which the EM car moves back and forth.",
        "topic": "Fun with Magnets",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "7cd97305-eba2-4fb0-86d1-038a5834e93f",
        "activity_name": "Solenoid engine",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Observing the magnetic effect of current due to which the piston of the engine moves back and forth.",
        "topic": "Fun with Magnets",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "d34d653d-eb42-46bb-a5a3-f2abf64eebd5",
        "activity_name": "Electromagnetic cannon",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Observing the magnetic effect of the current that accelerates the nail.",
        "topic": "Fun with EM",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "6a9152e4-c4be-4914-bc79-2709b4c9381d",
        "activity_name": "Deflection of filament of bulb in magnetic field (effect of AC vs DC)",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Observing the magnetic effect of current due to AC and DC",
        "topic": "Lorentz Force: AC vs DC",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "16b23d41-f4f1-4705-8e4a-79a94f5bf2c5",
        "activity_name": "Tangential Galvanometer with compass needle",
        "grade": "XII",
        "chapter": "4. MOVING CHARGES & MAGNETISM",
        "concept": "Qualitative study of the principle of a galvanometer.",
        "topic": "Lorentz force: Torque on a current loop",
        "quarter": 1.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "0c129b0e-a78c-41a1-8da7-d338413cc99d",
        "activity_name": "Bar Magnet oscillates inside uniform magnetic field",
        "grade": "XII",
        "chapter": "4. MOVING CHARGES & MAGNETISM",
        "concept": "Oscillation of a bar magnet inside a magnetic field.",
        "topic": "Lorentz force: Torque on a current loop",
        "quarter": 1.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "1e4bc160-58e1-4f9c-9d9e-76f629d4b744",
        "activity_name": "DC ammeter",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Qualitative study of the working principle of an ammeter.",
        "topic": "Lorentz force: Torque on a current loop",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "6934cb1d-599d-415e-bee6-a151a0070193",
        "activity_name": "Simple DC  Electric Motor",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Observing the force on a current carrying loop.",
        "topic": "Electric motor",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "367cbdbe-c1b7-4e2b-bb04-07647f8e8ece",
        "activity_name": "NaCl Motor",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Observing the force on a current carrying conductor.",
        "topic": "Electric motor",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "3fe85784-e26b-4129-8964-851a04091eb6",
        "activity_name": "Homopolar motor",
        "grade": "XII",
        "chapter": "4. MOVING CHARGES & MAGNETISM",
        "concept": "Observing the force on a conductor.",
        "topic": "Fun with Magnets",
        "quarter": 1.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "793aa8dc-ce8d-41ed-b36a-07f4e11d95ad",
        "activity_name": "Gauss Cannon in Slow Motion",
        "grade": "XII",
        "chapter": "4. MOVING CHARGES & MAGNETISM",
        "concept": "The attractive force from the magnet accelerates the ball due to which it impacts the magnet at a higher speed than it was traveling before. Thus, the energy is transferred to the ball on the opposite end, giving it a speed that is almost the same velocity as the first ball, right at the moment before impact.",
        "topic": "Fun with Magnets",
        "quarter": 1.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "de6a7039-ff81-4171-83d3-d133f02c1949",
        "activity_name": "Magnetic force demonstrator",
        "grade": "VI",
        "chapter": "13. FUN WITH MAGNETS",
        "concept": "1. Hold the setup in your hand.\n2. Observe that the arms of the setup are separated.\n3. Try to join the arms together.\n4. Push one arm towards the other and observe that the other arm moves away.\n5. Vibrate one arm and observe that the other arm starts vibrating.",
        "topic": "Magnetic force",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "cf0ecdb4-560b-4566-b8f9-b9a5c8d9861d",
        "activity_name": "Modular speaker",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Qualitative analysis of the working principle of a speaker  and propagation of sound through a medium.",
        "topic": "Sound conduction through solids.",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "3eeadfe2-36a7-4451-a534-991d775e78de",
        "activity_name": "Bone conduction speaker",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Qualitative analysis of the working principle of a speaker.",
        "topic": "Faraday's Law of Induced EMF",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "d6397c78-2b31-4d3b-828b-d4d7c3ef9dab",
        "activity_name": "Homemade speaker-1",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Qualitative analysis of the working principle of a speaker.",
        "topic": "Faraday's Law of induced EMF",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "8d1eb4a3-f859-4d9d-9fd4-3374333566de",
        "activity_name": "Homemade speaker-2",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Qualitative analysis of the working principle of a speaker.",
        "topic": "Faraday's Law of induced EMF",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "eb37f8ca-9e52-4af4-a830-379370c24c3c",
        "activity_name": "Homemade Headphone",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Demonstration of homemade headphones.",
        "topic": "Faraday's Law of Induced EMF",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "e762098d-029e-48e7-9853-09b4297ea46d",
        "activity_name": "Faraday's Law demonstration",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Demonstration of Faraday's law of induced EMF.",
        "topic": "Lorentz force: Torque on a current loop",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "EMI"
    },
    {
        "uuid": "5a9917b6-5208-4ff8-bc8e-58406ce2734a",
        "activity_name": "Lenz's law copper pipe",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Demonstration of Lenz's law and production of Eddy currents.",
        "topic": "Lenz's Rule",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "EMI"
    },
    {
        "uuid": "03b5795c-ff1f-4bf0-b172-9db2f1274e89",
        "activity_name": "Fleming Right Hand Rule",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Demonstration of Fleming's RH rule.",
        "topic": "Faraday's Law of Induced EMF",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "EMI"
    },
    {
        "uuid": "725fe73e-ff14-46e9-8285-051fcbafba89",
        "activity_name": "Lorentz Cube",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Qualitative analysis of the principle of electromagnetic induction",
        "topic": "Faraday's Law of Induced EMF",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Electromagnetism(MEC)"
    },
    {
        "uuid": "df67d97a-4e8d-4f5a-ab0d-875effb7247b",
        "activity_name": "Hand Generator",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Qualitative analysis of the principle of electromagnetic induction",
        "topic": "Faraday's Law of Induced EMF",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "EMI"
    },
    {
        "uuid": "125c913c-f929-4d1b-8b51-4bf8668ec5c1",
        "activity_name": "String Generator",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Qualitative analysis of the principle of electromagnetic induction",
        "topic": "Faraday's Law of Induced EMF",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "EMI"
    },
    {
        "uuid": "4fb16c76-5d3a-4a3c-a331-41f734081c06",
        "activity_name": "Dynamo Generator",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Qualitative analysis of the principle of electromagnetic induction",
        "topic": "Faraday's Law of Induced EMF",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "EMI"
    },
    {
        "uuid": "52bbc9f0-16b5-44b1-971b-b4f9e63ef38a",
        "activity_name": "Jumping Ring",
        "grade": "XII",
        "chapter": "6. ELECTROMAGNETIC INDUCTION",
        "concept": "Qualitative analysis of the principle of mutual induction and production of eddy currents",
        "topic": "Eddy Currents",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "EMI"
    },
    {
        "uuid": "5886bded-2852-4627-b5ab-5f30bf08514f",
        "activity_name": "EM braking using Eddy Current",
        "grade": "XII",
        "chapter": "6. ELECTROMAGNETIC INDUCTION",
        "concept": "Qualitative analysis of the production of eddy currents.",
        "topic": "Eddy currents",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "EMI"
    },
    {
        "uuid": "96ac07af-5f2f-46f5-b8f0-bf301595f3b8",
        "activity_name": "Electromagnetic Induction In Lab",
        "grade": "XII",
        "chapter": "6. ELECTROMAGNETIC INDUCTION",
        "concept": "Study of EMI",
        "topic": "Electromagnetic Induction",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "EMI"
    },
    {
        "uuid": "a992fc54-4510-45a0-9953-54aff7b2bbae",
        "activity_name": "Oscillating Magnet",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Qualitative analysis of the principle of mutual induction.",
        "topic": "Mutual Induction",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "EMI"
    },
    {
        "uuid": "50d524a9-315c-4560-8429-80dfd6460ebe",
        "activity_name": "Oscillating  Coil 1",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Qualitative analysis of the principle of mutual induction.",
        "topic": "Faraday's Law of induced EMF",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "EMI"
    },
    {
        "uuid": "11dcc110-a2fb-49cc-8115-4900592aa3e9",
        "activity_name": "Wireless Power Transmission",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Qualitative analysis of the working principle of a speaker.",
        "topic": "Speaker",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "EMI"
    },
    {
        "uuid": "e096714a-ccfc-44a9-b4f2-56813a58b471",
        "activity_name": "Inductor Radio",
        "grade": "X",
        "chapter": "13. MAGNETIC EFFECTS OF ELECTRIC CURRENT",
        "concept": "Application of mutual induction.",
        "topic": "Faraday's Law of induced EMF",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "EMI"
    },
    {
        "uuid": "1b345e26-ca37-4e1c-815c-5a03c4caf124",
        "activity_name": "Transmission Line",
        "grade": "XII",
        "chapter": "6. ELECTROMAGNETIC INDUCTION",
        "concept": "Demonstration of a Transmission Line",
        "topic": "Transmission Line",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "EMI"
    },
    {
        "uuid": "8fd085b8-e770-4890-b125-1de24af0c8d6",
        "activity_name": "Transformer",
        "grade": "XII",
        "chapter": "6. ELECTROMAGNETIC INDUCTION",
        "concept": "Qualitative analysis of working principle of a transformer",
        "topic": "AC",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "EMI"
    },
    {
        "uuid": "3d1e5714-2388-4dd0-9921-dd3449dd8b9b",
        "activity_name": "Rotational Motion: MI and Torque",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Qualitative study of MI and Torque using MI and Torque demonstration apparatus",
        "topic": "Rotational Motion: MI & Torque",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "c548f15f-8e79-48a8-859c-251184279197",
        "activity_name": "Rotational Motion: Maxwell's wheel",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Maxwell\u2019s wheel is a an axle-mounted flywheel suspended at both ends by a nylon cord. When the wheel is dropped, gravitational potential energy is converted to kinetic energy. The jerk when the wheel reaches the bottom reverses the linear momentum causing the wheel to wind back up. A small amount of energy is lost each time due to the friction of the nearly-elastic collision that occurs when the wheel jerks at the bottom. Studying the conservation of energy is unforgettable with this mesmerizing",
        "topic": "Rotational Motion: Translation & Rotation",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "07ff9b64-e45a-4db7-9d2e-4005533d68c2",
        "activity_name": "Rotational Motion: Instantaneous Axis of Rotation",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Demonstration of Instantaneous Axis of Rotation using a wire spool.",
        "topic": "Rotational Motion: IAOR",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "785f7ee0-0d01-463f-82d6-fdf2a7aa42a0",
        "activity_name": "Rotational Motion: Bottle with oil centrifuge",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Dependence of angular velocity with the angle made by the liquid surface with the horizontal.",
        "topic": "Circular Motion: Centrifuge",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "9e42b1d6-9aaf-4f5f-a5a3-84aa3f8834ac",
        "activity_name": "Rotational Motion: Jar with water centrifuge",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Dependence of angular velocity with the angle made by the balloon with the horizontal .",
        "topic": "Circular Motion: Centrifuge",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "91c64940-09e8-4496-b965-a83fee4c05a3",
        "activity_name": "Brachistochrone Paradox",
        "grade": "XI",
        "chapter": "6. WORK, ENERGY & POWER",
        "concept": "A curve of fastest descent. Also a tautochrone.",
        "topic": "WPE: Tracks",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "aa7101ec-62d1-4114-9703-fac69741691c",
        "activity_name": "Energy conservation: High-Low tracks",
        "grade": "XI",
        "chapter": "6. WORK, ENERGY & POWER",
        "concept": "On which track the ball will take less time to cover the same displacement?",
        "topic": "WPE: Tracks",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "94b90440-8f29-4aa6-a635-a215e45ba1de",
        "activity_name": "Energy conservation: Galilean tracks",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Both sides of the track are the same height,  one side is longer than the other side.  If a ball is released from one side can it roll off of the other side?  This track is said to have led Galileo to the idea of inertia.",
        "topic": "WPE: Tracks",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "75133413-27b8-4733-99b4-118cddf5e40c",
        "activity_name": "Hinged Stick and a Falling Ball",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Study of translation and rotation using Hinged stick",
        "topic": "Rotational Motion: Translation & Rotation",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "53f9cfe7-b7d3-4f90-a3e5-ccb310a44980",
        "activity_name": "Rotational Motion: Yoyo",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Qualitative study of the relationship between potential and kinetic energy, linear momentum, angular momentum, nearly-elastic collisions, inertia, and torque using a Maxwell's wheel.",
        "topic": "Rotational Motion: Translation & Rotation",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "8ec463fd-9744-4507-a853-38e7ce934b9b",
        "activity_name": "Rotational Motion: Looping Motion",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Qualitative study of the conservation of angular momentum using a looping pendulum. In this case, no matter what we do, the bigger mass cannot hit the bottom.",
        "topic": "Rotational Motion: Angular Motion",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "3f6a7dc6-6362-475a-858e-09c9b8849db2",
        "activity_name": "Rotational Motion: Glitter in rotating jar",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Qualitative study of the concept of centrifuge using spinning glitter apparatus.",
        "topic": "Circular Motion: Centrifuge",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "35197c99-d506-4b5e-b7ee-8c25ba930503",
        "activity_name": "Centripetal force: basketball and Stick",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Qualitative study of the centripetal acceleration using a basket ball and a stick.",
        "topic": "Circular Motion: Centripetal Acceleration",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "1f188f0a-29bc-4c6d-92ef-79d754a0990d",
        "activity_name": "Centripetal force: Centripetal force: CA setup",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Qualitative study of the centripetal acceleration using the circular track apparatus.",
        "topic": "Circular Motion: Centripetal Acceleration",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "7024df80-eff6-4dc7-b090-23a7d3cecdf3",
        "activity_name": "Rotational Motion: Torque, Angular momentum & friction",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Qualitative study of the torque and the angular momentum using a NM cart and a rotating disc.",
        "topic": "Rotational Motion: Angular Momentum",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "f463f7aa-ef55-4c8f-b092-00c724dc706a",
        "activity_name": "String shooter",
        "grade": "XI",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "A device with a loop made up of a string. When the wheels of that device rotates , the string pass through the wheels making the loop The string loop flies through the air, creating an interesting visual effect as it expands and contracts due to its circular shape.",
        "topic": "Rotational Motion: Translation & Rotation",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "212be42a-5d3c-4fe2-ac17-b146aa1d9f71",
        "activity_name": "Rotational Motion: Conservation of Angular Momentum",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Qualitative study of conservation of angular momentum using a rotating platform and dumbbell.",
        "topic": "Rotational Motion: Angular Momentum",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "d2fa67a3-4e35-413e-add4-14d1d51b4c5a",
        "activity_name": "Balancing toys and rotational equilibrium.",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Qualitative study of rotational equilibrium using balancing toys.",
        "topic": "Rotational Motion: Equilibrium",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "e0c1eec7-2779-432d-a3ed-549d9ea516c0",
        "activity_name": "Rotational Motion: Rolling",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Qualitative study of rolling of various objects with varying parameters to see which rolls down faster & quickest.",
        "topic": "Rotational Motion: Rolling",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "135ccd4a-ec26-4109-ac82-f3e174e413dd",
        "activity_name": "Bicycle wheel gyroscope",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Qualitative study of application of angular momentum in gyroscope by using a bicycle wheel and rotating platform.",
        "topic": "Rotational Motion: Angular Momentum",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "cc6832c3-8c90-4e0b-8b2f-5e4b5316c1b7",
        "activity_name": "COM an stability",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Qualitative study of COG using COM stability set up.",
        "topic": "COM: COG",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "2cafc207-9b6a-4772-982b-6781183fba89",
        "activity_name": "COM: Trajectory of COM",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Qualitative study of the process of tracing the trajectory of the COM.",
        "topic": "COM: Motion of COM",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "00ff10d9-5323-49f5-a533-32ad0dd199fa",
        "activity_name": "COM: Position of COM",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Qualitative study of the process of locating COM using plumbline.",
        "topic": "COM: Locating COM",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "35633096-e9d0-4fb0-8655-c75ebeed502a",
        "activity_name": "COM: Motion of COM in double cone",
        "grade": "XI",
        "chapter": "10. GRAVITATION",
        "concept": "Qualitative study of the motion of COM using a double cone. The double cone seems to be going uphill. Actually the com is going down due to increasing separation.",
        "topic": "COM: COG",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "dc125f18-d3e4-4fc5-b212-56861e17095c",
        "activity_name": "Gauss cannon",
        "grade": "XI",
        "chapter": "6. WORK, ENERGY & POWER",
        "concept": "Qualitative study of elastic and inelastic collisions using Gauss cannon.",
        "topic": "Collision: Elastic & Inelastic Collisions",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "fae3c77f-55be-42b8-a279-0853b8030062",
        "activity_name": "Centripetal force: Swinging Tray",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Study of centripetal acceleration using swinging tray",
        "topic": "Circular Motion: Centripetal Acceleration",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "1ee75b20-38fe-4879-a991-db224ed4d765",
        "activity_name": "Vertical circle",
        "grade": "XI",
        "chapter": "6. WORK, ENERGY & POWER",
        "concept": "WPE in vertical circle propblem",
        "topic": "WPE: Tracks",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "dbac533a-50ba-4afa-b56a-36eb5c759e90",
        "activity_name": "Uniform Circular Motion: Centrifugal vs Centripetal force",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Centrifugal vs Centripetal force",
        "topic": "Centripetal vs centrifugal",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "6ff45f61-4928-42d8-9a55-c8c1e12c1280",
        "activity_name": "Accelerated Circular motion: NM cart on Rotating disc",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Study of accelerated circular motion using NM cart",
        "topic": "Circular Motion: Centripetal Acceleration",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "26515d46-8256-41c7-9a7b-17967a3cf076",
        "activity_name": "Uniform circular motion: \u03c9 ~ R",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Study of uniform circular motion and angular acceleration using a wheel",
        "topic": "Circular Motion: Angular Acceleration",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "b1320b9f-3575-461b-bafa-e9e2bae30317",
        "activity_name": "Uniform circular motion: UM car",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Study of uniform circular motion and angular acceleration using a UM car",
        "topic": "Uniform Circular Motion",
        "quarter": 2.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "af715a2b-ce6f-459e-8325-c615ebe8743e",
        "activity_name": "COM: Breaking pole paradox",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Study of COM",
        "topic": "COM",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "677b9dfc-2cee-4c9d-abe9-f2fbe906169b",
        "activity_name": "Gyroscopic Vehicle",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Conservation of angular momentum",
        "topic": "Rotational Motion: Gyro",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "8af276ed-0944-40f3-9e8d-ab71615d319f",
        "activity_name": "Interrupted Pendulum",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Study of circular motion",
        "topic": "WPE: Vertical Circle",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "94f7522c-28ba-4ee5-abab-da9c36641a1a",
        "activity_name": "Amazing Pendulum",
        "grade": "XI",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Amazing pendulum effect",
        "topic": "amazing pendulum effect",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "3e6b35eb-4cd9-4b10-af2c-01d61104b932",
        "activity_name": "Joints",
        "grade": "VI",
        "chapter": "8. BODY MOVEMENTS",
        "concept": "Study of levers",
        "topic": "Levers",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "d5bed3d6-83e5-42ca-bb26-1268f410efb3",
        "activity_name": "Energy conservation Paradox",
        "grade": "IX",
        "chapter": "11. WORK AND ENERGY",
        "concept": "The conservation of energy apparatus has 4 tracks. When the ball is released from the top of the  each ramp, it rolls down the ramp. So the potential energy of the ball is converted into the kinetic energy.",
        "topic": "WPE: Tracks",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "663f9e32-8300-4d77-a91a-2d2ad01723d3",
        "activity_name": "COM: Motion of COM",
        "grade": "XI",
        "chapter": "7. SYSTEM OF PARTICLES & ROTATIONAL MOTION",
        "concept": "Study of motion of COM",
        "topic": "Motion of COM",
        "quarter": 2.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "1bf5817a-6e55-4588-ad2b-f1babb010b02",
        "activity_name": "Cycloidal Newton's cradle",
        "grade": "XI",
        "chapter": "6. WORK, ENERGY & POWER",
        "concept": "Study of law of energy and momentum conservation",
        "topic": "Energy and Momentum conservation",
        "quarter": 2.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "WPE/Rotational Motion"
    },
    {
        "uuid": "12a545d2-305d-42c2-8b9b-0b212b08c19f",
        "activity_name": "Elliptical orbits analog",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Study of orbit formation in Gravitation",
        "topic": "Gravitation: Orbits",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Gravitation"
    },
    {
        "uuid": "6ea26b04-6642-4040-9361-15809668f16f",
        "activity_name": "Gravity game",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Understanding Gravity",
        "topic": "Gravitation: Understanding",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Gravitation"
    },
    {
        "uuid": "b2257fa5-abc8-49f9-9b23-e27cfcd464a2",
        "activity_name": "SHM: Balls",
        "grade": "VII",
        "chapter": "13. MOTION AND TIME",
        "concept": "Qualitative study of SHM using a ball hanging from a stand by a thread.",
        "topic": "SHM",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "877ad012-3d43-41f3-94d9-6157a53f192d",
        "activity_name": "SHM: Scales",
        "grade": "XI",
        "chapter": "14. OSCILLATIONS",
        "concept": "Qualitative study of SHM using a scale attached to a table by a clamp.",
        "topic": "SHM",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "2eb91e05-0ac8-4c2a-ad56-d22a44ed6032",
        "activity_name": "SHM: Double Pendulum(Horizontal)",
        "grade": "XI",
        "chapter": "14. OSCILLATIONS",
        "concept": "Qualitative study of energy transfer between the pendulums using the horizontal double pendulum setup.",
        "topic": "SHM",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "c46eb459-7dd1-49d0-9db2-c0d5a9f5ce91",
        "activity_name": "SHM: Springs",
        "grade": "XI",
        "chapter": "14. OSCILLATIONS",
        "concept": "Qualitative study of SHM using a weight attached spring hanging from a stand.",
        "topic": "SHM",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "dd6aeab5-7191-49ef-8440-21c2f391bdf4",
        "activity_name": "SHM: Vertical double pendulum",
        "grade": "XI",
        "chapter": "14. OSCILLATIONS",
        "concept": "Qualitative study of energy transfer between the pendulums using the vertical double pendulum setup.",
        "topic": "SHM",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "647c7e22-1b0c-4459-89eb-1063f399cdf1",
        "activity_name": "SHM: Sand Oscillator",
        "grade": "XI",
        "chapter": "14. OSCILLATIONS",
        "concept": "Qualitative study of SHM using the sand oscillator apparatus.",
        "topic": "SHM",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "18918c8a-32d6-48a5-b180-e69be40aee7a",
        "activity_name": "SHM: Projection of a rotating wheel.",
        "grade": "XI",
        "chapter": "14. OSCILLATIONS",
        "concept": "Projections of two oscillators (one cyclic, one longitudinal) show how both can be explained when observed in 1-D motion.\n",
        "topic": "SHM",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "5a79e92c-d1b3-4708-9cda-1e2d40110790",
        "activity_name": "SHM: Wrapping pendulum",
        "grade": "XI",
        "chapter": "14. OSCILLATIONS",
        "concept": "Qualitative study of SHM using a simple pendulum wrapped over the stand.",
        "topic": "SHM",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "eac5bef9-c24c-4489-aee6-ba02b0ed4a25",
        "activity_name": "Measurement of Time period of simple pendulum",
        "grade": "VII",
        "chapter": "13. MOTION AND TIME",
        "concept": "Measuring the time period of a simple pendulum wrapped over the stand.",
        "topic": "SHM",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "07be79bd-4e15-4435-b1d2-028ba2351899",
        "activity_name": "Oscillation: Factors affecting oscillation",
        "grade": "XI",
        "chapter": "14. OSCILLATIONS",
        "concept": "Qualitative study of factors affecting SHM using springs of different lengths and weight set.",
        "topic": "SHM",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "fd549c5b-a77f-42ea-bdae-218f455d834c",
        "activity_name": "Oscillation: Conservation of momentum",
        "grade": "XI",
        "chapter": "14. OSCILLATIONS",
        "concept": "Qualitative study of the principle of conservation of momentum in SHM using a mechanical bench setup",
        "topic": "SHM",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "b9807949-4940-4d1c-b959-f50402c5aab9",
        "activity_name": "Lissajous patterns",
        "grade": "XI",
        "chapter": "14. OSCILLATIONS",
        "concept": "Demonstration of Lissajous patterns using a sand pendulum.",
        "topic": "SHM",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "ff9ecf19-cd75-41af-b328-6016ddad7139",
        "activity_name": "Resonance: Coupled systems",
        "grade": "XI",
        "chapter": "14. OSCILLATIONS",
        "concept": "Demonstration of shifting of energy between the translational and torsional mode of oscillation in a coupledmechanical oscillator like wilberforce pendulum.",
        "topic": "Resonance",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "d6c3e652-e6b2-4453-8e85-e38288a9f721",
        "activity_name": "Resonance: Pendulum 1",
        "grade": "XI",
        "chapter": "14. OSCILLATIONS",
        "concept": "Demonstration of the concept of resonance using 4 simple pendulums hanging from a rod.",
        "topic": "Coupled Oscillations",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "9dcc94c7-aa59-468c-91d2-539d81130b91",
        "activity_name": "Resonance: Pendulum 2",
        "grade": "XI",
        "chapter": "14. OSCILLATIONS",
        "concept": "Demonstration of the concept of resonance using a simple pendulum hanging from an electronic oscillator.",
        "topic": "Resonance",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "fb9724f9-783f-44ac-b777-031ca6c19138",
        "activity_name": "Resonance: Spring",
        "grade": "XI",
        "chapter": "14. OSCILLATIONS",
        "concept": "Demonstration of the concept of resonance using  a spring oscillator hanging from an electronic oscillator.",
        "topic": "Resonance",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "6db465b5-66bf-4ae0-804d-be4dffa3249e",
        "activity_name": "Resonance: Coupled Air Carts",
        "grade": "XI",
        "chapter": "14. OSCILLATIONS",
        "concept": "Demonstration of the concept of resonance using a mechanical bench setup.",
        "topic": "Coupled Oscillations",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "d737c177-63d5-4a9e-88ed-941c09c5aa99",
        "activity_name": "Pendulum magic",
        "grade": "XI",
        "chapter": "14. OSCILLATIONS",
        "concept": "Demonstartion of different patterns that are created when all of the pendulums with different lengths in the amazing pendulum setup are started at once. The pattern resets after about 1 minute.",
        "topic": "Wave Pattern",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "7a1b4792-64ad-4bf0-b2ba-e2c4eabf35c1",
        "activity_name": "Music from a pipe",
        "grade": "XI",
        "chapter": "12. SOUND",
        "concept": "Demonstration of the concept of resonance by heating the mesh screen in the bottom of the pipe which creates a flow of air that causes the pipe to resonate, thereby creating a tone. When the pipe is tilted sideways, the flow of air is disrupted, which eliminates to the tone.",
        "topic": "Resonance and Sound",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "376c0dea-087f-4161-8792-72ba9379122d",
        "activity_name": "Music from a rod",
        "grade": "XI",
        "chapter": "12. SOUND",
        "concept": "Demonstration of creation of sound in metal xylophone.",
        "topic": "Sound in Solids",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "714d75c5-e841-4f47-baa9-42420650d9fb",
        "activity_name": "Waves in a rope",
        "grade": "IX",
        "chapter": "12. SOUND",
        "concept": "Demonstration of speed of the wave in a moving medium.",
        "topic": "Waves in a medium",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "3060c777-e91a-4812-9b8b-dc6722b7be22",
        "activity_name": "Sound through a tube",
        "grade": "IX",
        "chapter": "12. SOUND",
        "concept": "Demonstration of the propagation of the sound wave through a tube with a very little loss of energy.",
        "topic": "Propagation of sound and Plane Wavefront",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "4889825d-3210-47fe-a1bc-2b9e4afcdb29",
        "activity_name": "Sound in vacuum",
        "grade": "IX",
        "chapter": "12. SOUND",
        "concept": "Demonstration of the propagation of the logitudinal waves(sound) that depends on the dnesity of the medium using a vacuum chamber, a mechanical bell and a vacuum pump.",
        "topic": "Propagation of Sound",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "58a78d7a-9bd1-446d-bd9e-d75c725cc916",
        "activity_name": "Transverse and Longitudinal Waves demonstration",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Demonstration of propagation of transverse and logitudinal wave using a slinky.",
        "topic": "Transverse and Longitudinal Waves",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "c77f2015-db2f-4074-bb9f-1fe7b7926155",
        "activity_name": "Frequency & Amplitude of a wave",
        "grade": "IX",
        "chapter": "12. SOUND",
        "concept": "Qualitative study of the frequency and amplitude of a wave using a signal generator and Oscilloscope(Mobile app oscope or SmartScope).",
        "topic": "Frequency",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "30e475f0-d135-465e-976b-dfc9cbf3d90b",
        "activity_name": "Wave reflections 1",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Qualitative study of reflection of waves using a wave machine and a slinky.",
        "topic": "Transverse wave: Propagation, Reflection, Transmission, Speed, Frequency",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "4d08bd07-cd9f-47cc-838d-2d909998bbc7",
        "activity_name": "Ripple tank experiment",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Demonstration of water waves using a ripple tank experiment apparatus.",
        "topic": "Transverse wave: Waveform, Wavefronts, Superposition, Boundary",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "ed9552f1-0c77-4367-94c9-e4dfbe482ac1",
        "activity_name": "Destructive Interference Demonstration",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Demonstration of the phenomenon of destructive interference of waves using a slinky and a spring.",
        "topic": "Transverse wave: Interference of Waves",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "2b8c06c3-d5a4-4fb1-9198-23df864ce514",
        "activity_name": "Constructive Interference Demonstration",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Demonstration of the phenomenon of constructive interference of waves using a slinky and a spring.",
        "topic": "Transverse wave: Interference of Waves",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "2c1e0cd2-449f-40b9-acf5-eee10004e144",
        "activity_name": "Moving pulse",
        "grade": "IX",
        "chapter": "12. SOUND",
        "concept": "Demonstration of the propagation of a pulse using a steel ribbon and a slinky.",
        "topic": "Pulse: Propagation",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "fe91201d-dcff-4b1e-9668-4ec7e3addc4b",
        "activity_name": "Open end Pulse reflection",
        "grade": "IX",
        "chapter": "12. SOUND",
        "concept": "Demonstration of the phenomenon of reflection of a pulse at a open boundery(Open end) using a steel ribbon and a slinky.",
        "topic": "Pulse: Reflection",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "e9283436-2211-4220-964a-66a26c1f0c18",
        "activity_name": "Fixed end Pulse reflection",
        "grade": "IX",
        "chapter": "12. SOUND",
        "concept": "Demonstration of the phenomenon of reflection of a pulse at a rigid boundery(Closed end) using a steel ribbon and a slinky.",
        "topic": "Pulse: Reflection",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "2c726825-2231-440e-a504-b49c62733fc0",
        "activity_name": "Sounds in musical instruments",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Musical instrument: Xylophone",
        "topic": "Musical Instrument",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "37d5b2d7-1d4d-4309-9f19-1c625fd839d7",
        "activity_name": "Speakers from household items 1",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Demonstration of sound as a pressure wave using a thermocol mounted speaker with motor and an amplifier.",
        "topic": "Sound in solids",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "df781aa9-75a3-4cb2-af26-623cba86637c",
        "activity_name": "Speakers from household items 2",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Demonstration of sound as a pressure wave using a thermocol mounted speaker without motor and an amplifier.",
        "topic": "Sound in solids",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "c2db4aba-6015-49ce-9f35-aaf1ab7a4fe1",
        "activity_name": "Tuning fork fun: Usages",
        "grade": "IX",
        "chapter": "12. SOUND",
        "concept": "Demonstration of production of sound in a vibrating object such as tuning forks.",
        "topic": "Vibration in Tuning fork",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "38ff77b9-c7df-4368-9f12-af1afe63040a",
        "activity_name": "Tuning fork fun: Ripple in water",
        "grade": "IX",
        "chapter": "12. SOUND",
        "concept": "Demonstration of production of ripples in water due to a vibrating tuning fork using tuning forks of different frequencies and a ripple tank.",
        "topic": "Vibration in Tuning fork",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "40ab9f7f-0a61-41b8-9dd6-c8ac39ae3525",
        "activity_name": "Tuning fork fun: Visualization of vibrating arms",
        "grade": "IX",
        "chapter": "12. SOUND",
        "concept": "Visualisation of vibrating arms of a tuning fork using tuning forks of different frequencies and a stroboscope.",
        "topic": "Vibration in Tuning fork",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "dab4190d-d25f-479e-9f0b-c12083a3040e",
        "activity_name": "Tuning fork fun: Visualization of the sound wave",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Visualisation of sound waves produced in the tuning fork by using tuning forks of different frequencies and an Oscilloscope(Mobile app oscope or SmartScope).",
        "topic": "Frequency of Sound",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "dfaf30d6-15cb-4fac-8083-b679065056ea",
        "activity_name": "Tuning fork fun: Resonance",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Demonstration of the phenomenon of resonance using two tuning forks of same frequency and a simple pendulum hanging from a stand. \n\n",
        "topic": "Resonance",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "655b47a4-56df-4a8d-b90f-433acbd171d7",
        "activity_name": "Tuning fork fun: Sound via light",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Visualisation of sound production in a tuning fork and transportation of it via light using tuning forks of different frequencies, an Oscilloscope(Mobile app oscope or SmartScope), photo diode and a laser pointer.",
        "topic": "Vibration in Tuning fork",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "5638a2d6-f7fd-4e0d-9a1b-c148af125e72",
        "activity_name": "Tuning fork fun: Interference and Beats",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Demonstration of phenomenon of beats using two tuning forks with slightly different frequencies, a  speaker and an Oscilloscope(Mobile app oscope or SmartScope).",
        "topic": "Beats",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "f6dfef68-d384-4dc2-9e49-48fd8729231f",
        "activity_name": "Tuning fork fun: Resonance column",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Demonstration of principle of resonance using a tuning fork and pipe and calculation of the speed of sound.",
        "topic": "Resonance and speed of sound determination",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "6c6d75b6-33ef-48f9-8860-a94d51c4bf1d",
        "activity_name": "Tuning fork fun: Lissajous Figures",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Qualitative study of Lissajous figures using a tuning fork and lase pointer.",
        "topic": "Vibrations in 2-D",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "96cd9383-a314-4a49-b162-a14433f547c6",
        "activity_name": "Tuning fork fun: Vibrating chord",
        "grade": "IX",
        "chapter": "12. SOUND",
        "concept": "Misc. Tuning fork Experiments",
        "topic": "Tuning forks",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "6554bf3d-b49c-4b1d-8df3-26c90128b49f",
        "activity_name": "Tuning fork fun: Tuning a tuning fork",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Misc. Tuning fork Experiments",
        "topic": "Tuning forks",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "23b54bcd-736d-4f3e-83cb-388aa651bfcc",
        "activity_name": "Stationary Wave: Tension in a wire",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Qualitative study of stationary waves in a string using an electronic oscillator and a string.",
        "topic": "Stationary wave",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "9ae76cd1-d693-4fdf-872c-6c69d2ac415a",
        "activity_name": "Stationary Wave: Metal strip magic",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Qualitative study of stationary waves in metal resonance strips using an electronic oscillator and metal resonance strips.",
        "topic": "Stationary wave",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "6a07355b-cb4b-4542-a8cf-ac8519dfd0c4",
        "activity_name": "Stationary Wave: Metal wire magic",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Qualitative study of stationary waves in resonance wire loop using an electronic oscillator and resonance wire looping.",
        "topic": "Stationary wave",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "36df18a6-098d-4974-aab1-396bbe5f517a",
        "activity_name": "Stationary Wave: Plates",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Qualitative study of stationary waves in plates using an electronic oscillator and chladini plates.",
        "topic": "Stationary wave",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "8ceea059-d3ca-4e0f-afb4-942cce10cc87",
        "activity_name": "Stationary sound waves: Kundt's Tube",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Qualitative study of stationary sound waves using Kundt's tube setup.",
        "topic": "Resonance",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "0cc5ac0d-5e9e-4b9e-be9f-db3aa805eac3",
        "activity_name": "Stationary sound waves: Resonance column",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Qualitative study of production of staitionary waves in sound in different organ pipes using tubes of different lengths, bottles and Tuning forks.",
        "topic": "Resonance",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "df3c575e-0035-4e18-aef8-9889ae098c30",
        "activity_name": "Miscellaneous sound experiments",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Some miscellaneous experiments showing sound as a pressure wave.",
        "topic": "Sound",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "6efb4b6a-c508-4cd4-8b21-ebd3d99659e1",
        "activity_name": "Sounding rods",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Production of sound from a rod",
        "topic": "Sound",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "3c1d2a24-365e-4dc6-af47-bf55406e97be",
        "activity_name": "Resonance: Glass and water",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Demonstration of the phenomenon of resonance using several wine glasses.",
        "topic": "Resonance",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "4b7d7737-4abc-42c4-b76b-b7fdc50cc9e8",
        "activity_name": "Standing waves magic",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Demonstration of the phenomenon of ultrasonic levitation using Acoustic Levitator Setup.",
        "topic": "Stationary wave, Ultrasonic Levitation",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "ab677af7-454f-472e-b3c9-ffb04542c6a4",
        "activity_name": "Beat Phenomena: Tuning Forks",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Study of beat phenomena using tuning forks",
        "topic": "Beats, Beat frquency observed using mobile phone",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "ba89fed2-9f5f-42ae-8a75-54ae677288d0",
        "activity_name": "Beat phenomena: Visualizing Beat Frequencies",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Visualization of the phenomenon of beat using electronic oscillators, signal generators and Oscilloscope(Mobile app oscope or SmartScope).",
        "topic": "Beats, Beat frquency observed using mobile phone",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "b529283c-4fdf-4c92-b7d0-6a21936a4eb1",
        "activity_name": "Doppler effect of sound: Tube",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Demonstration of doppler effect of sound using a 1 metre long pipe.",
        "topic": "Doppler's effect of sound",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "0e41c6ae-cd04-4925-a9c6-54091aea19df",
        "activity_name": "Doppler effect of sound: Ball",
        "grade": "XI",
        "chapter": "15. WAVES",
        "concept": "Study of doppler effect of sound",
        "topic": "Doppler's effect of sound",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "74822464-5612-4815-8479-56475f56b4d3",
        "activity_name": "Sound from vibrations: Striking pan",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Demonstration of production of sound and its effect using a pan.",
        "topic": "Types of motion",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "948d9b33-2c13-423c-aba8-25a8afc1756c",
        "activity_name": "Sound from vibrations: Plucking the rubber band",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Demonstration of productionof sound from a vibrating object using a rubber band.",
        "topic": "Types of motion",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "53433a5b-a943-4c9a-8164-37b03e168a69",
        "activity_name": "Water waves",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Study of wave motion by observing ripples produced in water",
        "topic": "Types of motion",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "84fe0359-4365-4e09-95a5-a128082fa893",
        "activity_name": "Sound from vibrations: Ektara",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Musical instruments",
        "topic": "Types of motion",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "cd4319ea-31ad-4d5a-853e-bf3db806535a",
        "activity_name": "Sound from vibrations: Jaltarang",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Demonstration of production of sound from vibrations using Jaltarang.",
        "topic": "Types of motion",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "b01cfeca-82c1-4da5-9c2b-a7d736e78fb4",
        "activity_name": "Sound from vibrations: vocal cords",
        "grade": "IX",
        "chapter": "12. SOUND",
        "concept": "Demonstration of sound a pressure wave using a sound visualiser gadget.",
        "topic": "Types of motion",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "47eaeac8-b53f-400c-a33b-a8add114ac8c",
        "activity_name": "Sound from vibrations: Visualising sound",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Demonstration of patterns produced in sounds using a sound visualiser gadget.",
        "topic": "Sound",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "2e8002b7-4421-4ca1-8e6a-f391b06069aa",
        "activity_name": "Pendulum car",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Demonstration of converision of energy associated with oscillation into translational energy.",
        "topic": "Types of motion",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "d62ae16b-afe6-411e-825f-bdc5eca83547",
        "activity_name": "Human ear model",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Demonstration of human ear.",
        "topic": "Sound",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "54ed4e7a-3fb2-471d-89c5-e89080a726dc",
        "activity_name": "Seismograph principle",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Demonstration detection of seismic waves using a seismograph.",
        "topic": "Earthquakes",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "774c1fa7-7f8f-4de0-bc35-8640b54518a6",
        "activity_name": "Art in vibration",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Every vibration produces a pattern",
        "topic": "Types of motion",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "ac8585c6-3c2e-4371-9b1f-c7fc3186401b",
        "activity_name": "Musical pipe",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "open end organ pipe of different diameter\nproduces different pitch.",
        "topic": "open end organ pipe:Resonance",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "cb3cc09d-4eb8-45bf-a3e1-4001d71629bc",
        "activity_name": "Bottle flute",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Demonstration of a sound producing device called as bottle flute.",
        "topic": "Resonance",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "a5d0323d-1b11-4361-8ff5-f32ebf7d8b51",
        "activity_name": "Microphone",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Demonstration of a homemade microphone that converts sound into electrical signal and vice versa.",
        "topic": "Sound",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "ecd675c6-4951-4562-8697-be532956b5b7",
        "activity_name": "Sound in water",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Demonstration of production of sound from various vibrating objects.",
        "topic": "Production of sound",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "3cd6034a-c55e-4e4e-b4f7-c72e8a5d18a4",
        "activity_name": "Sound in solids",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Demonstration of propagation of sound through water using a bluetooth speaker.",
        "topic": "Sound through a medium",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "6646d31e-94af-42f5-b5d6-69cfeec6d5c5",
        "activity_name": "Homemade telephone",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Demonstration of the following phenomena\n1. sounds travels faster in solid\n2. Sound does not travel only in straight line",
        "topic": "Sound through a medium",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "9d174ddb-9c00-43a5-bdfb-65a3053fd41e",
        "activity_name": "Speed of sound demonstration",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Speed of sound measurement with the principle of resonance",
        "topic": "Resonance and speed of sound determination",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "bdec6cd8-a768-4752-ae4e-31aaa75151d0",
        "activity_name": "Pitch of a Sound wave",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Demonstration of dependence of pitch of a sound on vibrations.",
        "topic": "Pitch",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "b350af17-637f-4de7-ac8f-8c340907455b",
        "activity_name": "Flute from a straw",
        "grade": "VIII",
        "chapter": "13. SOUND",
        "concept": "Demonstration of production of sound in a straw used as a flute.",
        "topic": "Resonance",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "SHM and Waves"
    },
    {
        "uuid": "f987cc70-59f4-47ec-9f77-3fe675c9a139",
        "activity_name": "Balloon on nails",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Qualitative study of air pressure using balloon on nails apparatus",
        "topic": "Air pressure",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "ec125599-010d-4809-981f-67a8b0321843",
        "activity_name": "Air pressure paradox",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Qualitative study of air pressure using two sticks and news papers",
        "topic": "Air pressure",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "5b003c7f-02b2-4097-a4bf-e4b2dea8160b",
        "activity_name": "Pressure cups",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Qualitative stusy of air pressure using pressure cups",
        "topic": "Air pressure",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "a958a7ea-af5c-4ef7-9d47-08bc88831e86",
        "activity_name": "Pressure mats",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Qualitative stusy of air pressure using pressure mats",
        "topic": "Air pressure",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "5c10f935-8a20-42a4-8684-9f48c1b934b2",
        "activity_name": "Vacuum hanging",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Study of air pressure and vacuum",
        "topic": "Vacuum",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "ae289bb7-3335-4848-a64b-0713bd078857",
        "activity_name": "Balloon Expansion in vacuum",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Study of air pressure and vacuum",
        "topic": "Vacuum",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "d22aa685-cff4-438d-9dc9-f92a9838d6ba",
        "activity_name": "Boiling point of water in vacuum",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Study of air pressure and vacuum",
        "topic": "Vacuum",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "c74653aa-38d0-4cde-a9fe-15e4f34ccc1d",
        "activity_name": "fan in vacuum",
        "grade": "VI",
        "chapter": "15. AIR AROUND US",
        "concept": "Study of air pressure and vacuum",
        "topic": "Vacuum",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "597b9b2c-31bf-4f10-b8e5-b4a10e61fb1e",
        "activity_name": "Reverse siphon in vacuum",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Study of air pressure and vacuum",
        "topic": "Vacuum",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "01587998-c59f-4fd3-988f-3fbbbf4ead28",
        "activity_name": "Nichrome wire cooling in vacuum",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Study of air pressure and vacuum",
        "topic": "Vacuum",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "179b66c1-43a6-41a9-86fa-3ca0b2b8aedc",
        "activity_name": "Plasma arc formation in vacuum",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Study of air pressure and vacuum",
        "topic": "Vacuum",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "9c0b799d-8856-4d54-a2c4-53c5485a851f",
        "activity_name": "Balloon tricks 1",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Some ballon tricks",
        "topic": "Air pressure",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "bc64320d-9969-43fa-9bb9-496a218e0d14",
        "activity_name": "Balloon tricks 2",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Some ballon tricks",
        "topic": "Air pressure",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "c67e4464-b5eb-4676-afd7-14363fb8e153",
        "activity_name": "Pressure launcher",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Study of thrust using the potato gun set up",
        "topic": "Air pressure",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "362e917d-7343-493c-9710-e2a7e0ddfa6f",
        "activity_name": "Pressure in liquids 1",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Study of pressure in liquids",
        "topic": "Fluid pressure",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "7ad7d1dd-7c3c-433b-9ab0-7c079bd52ade",
        "activity_name": "Pressure in liquids 2",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Study of pressure in liquids",
        "topic": "Fluid pressure",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "eacb53f2-4355-4b25-a9a1-760afb3e61c9",
        "activity_name": "Archimedes principle",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Study of Archimedes principle using mustard seeds, balls and a container",
        "topic": "Archimedes principle",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "782b00f3-1cf6-4f82-abcf-1e34d183cb43",
        "activity_name": "Pascal demonstrator",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Study of Pascal's law",
        "topic": "Pascal's Law",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "9220d32c-ffda-49e0-928c-cab6528ebbf1",
        "activity_name": "Floating Paradox",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Study of buoyancy and its effect.",
        "topic": "Pascal's Law",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "63fe4696-4cbc-461d-8d55-7119d9fbdbfd",
        "activity_name": "Diver1",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Study of archimedes principle using diver 1",
        "topic": "Archimedes principle",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "9fdf8c8d-344c-47e6-91bd-35990c70ad73",
        "activity_name": "Diver2",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Study of archimedes principle using diver 2",
        "topic": "Archimedes principle",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "fd8cfe15-a1c7-42c9-9231-a116f5c15610",
        "activity_name": "Air pressure: Magdeburg in water",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Study of airpressure using magdeburg",
        "topic": "Air pressure",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "b79d6319-a328-4d40-b83b-33fa61c13a15",
        "activity_name": "Air pressure: Magdeburg and vacuum pump",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Study of airpressure using magdeburg",
        "topic": "Hydrostatics",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "fa338750-1ffb-4702-83a6-932c7ebfc728",
        "activity_name": "Air pressure: Holding up water",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "A fun experiment to show the effect of air pressure",
        "topic": "Air pressure",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "c2402604-3fac-494d-91ee-56df364185bd",
        "activity_name": "Equation of Continuity",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Study of equation of continuity and it's application",
        "topic": "Air pressure",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "537131b7-edf2-43c8-819b-f8aff5615807",
        "activity_name": "Venturi",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Study of application of Bernoulli's effect using a venturi",
        "topic": "Bernoulli's effect",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "e23c0c13-64b2-48a3-bda8-9e5db823cb62",
        "activity_name": "Bernoulli's effect: Blowing between the objects",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Demonstration of Bernoulli's effect using two glass jars and some pencils.",
        "topic": "Bernoulli's effect",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "3a593277-4224-420c-bdc0-12abc99003d0",
        "activity_name": "Bernoulli's effect: Air blower on the ball",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Demonstration of Bernoulli's effect using a blower and a ball",
        "topic": "Bernoulli's effect",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "3eb862f7-5155-48ed-bad2-6adb78f54f4a",
        "activity_name": "Basic Siphon",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Fun experiments with Coheshive force and air pressure demonstration using Various siphons",
        "topic": "Bernoulli's effect",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "812683ef-e992-41aa-9479-c2543ea8b63a",
        "activity_name": "Flying drop Siphon",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Fun experiments with Coheshive force and air pressure demonstration using Various siphons",
        "topic": "Siphon",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "a9fc9887-e050-41ce-b5c7-37c756355798",
        "activity_name": "Cup Siphon",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Fun experiments with Coheshive force and air pressure demonstration using Various siphons",
        "topic": "Siphon",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "7e6f461c-7e83-4b71-94c4-51b4ee5e139b",
        "activity_name": "Bell Siphon",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Fun experiments with Coheshive force and air pressure demonstration using Various siphons",
        "topic": "Siphon",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "00a2a0f5-7075-4ae8-8c41-a697ae73b91c",
        "activity_name": "U shaped Siphon",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Fun experiments with Coheshive force and air pressure demonstration using Various siphons",
        "topic": "Siphon",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "084ebb7e-7131-49ee-af24-e44656701dbe",
        "activity_name": "Flying cups",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Syphonic in which Liquid flow upward above the surface of reservoir",
        "topic": "Siphon",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "cf8b2f60-0eae-454a-a648-17765980fc95",
        "activity_name": "Smoke rings",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Fun with bernoulli's effect",
        "topic": "Bernoulli's effect",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "f2380391-6606-4254-a030-1205d0927093",
        "activity_name": "Capillary effect",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Study of capillarity",
        "topic": "Capillary effect",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "08745bc1-8575-4b0f-b295-02ac1f7ddc8e",
        "activity_name": "Soap films",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Study of surface tensions using soap films",
        "topic": "Surface tension",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "6a923609-1c5e-46ee-8cac-06cb33b12585",
        "activity_name": "Rotating water tanks",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Study of viscocity",
        "topic": "Viscosity",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "ccdd85a3-d363-415e-8588-73f3f83a77a4",
        "activity_name": "Maths & kinematics",
        "grade": "IX",
        "chapter": "9. FORCE AND LAWS OF MOTION",
        "concept": "Fun with math",
        "topic": "maths & kinematics",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "97b08e82-d028-46f6-af0a-49e009a74829",
        "activity_name": "Pressure measurement: Manometer",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Pressure measurements",
        "topic": "Air pressure",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "5010fd37-2b77-4864-b3a6-f56a10d64392",
        "activity_name": "Air Pressure: Tissue and Glass",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Study of air pressure using a plastic glass, a tissue and a water container",
        "topic": "Air pressure",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "8d70fb77-8be5-42fa-8675-ddf67b1b6c62",
        "activity_name": "Buoyancy demonstration",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Study of buoyancy and its effect",
        "topic": "Buoyancy",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "d8251514-e9bb-4ae5-990b-6f41b7f67239",
        "activity_name": "Water pressure paradox",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "A fun experiment with water pressure",
        "topic": "Hydrostatic Pressure",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "b5f545a7-fb9d-4283-9514-15a23a534d43",
        "activity_name": "Buoyancy paradox",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Study of buoyancy and its effect",
        "topic": "Buoyancy",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "95d5da29-de49-465c-b4e3-6ee3956728c2",
        "activity_name": "Buoyancy with balloon",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Study of buoyancy and its effect",
        "topic": "Buoyancy",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "ae5c4a72-4799-4fb9-9572-6c2e2c220e52",
        "activity_name": "U tube magic",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Some hydrostatic phenomena",
        "topic": "Fluid mechanics",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "19f3edc3-f3bc-4201-b0d4-ab378b5a4b79",
        "activity_name": "Some hydrostatics phenomena",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Some hydrostatic phenomena",
        "topic": "Hydrostatics",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "4c58e740-be11-45f0-a6d3-2d12bdf57236",
        "activity_name": "Pressure due to liquid column: Pascal's barrel",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "Study of Pascal's law using a pipe and a Jar",
        "topic": "Pascal's Law",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "1b4e8117-b998-4bad-946b-a0105a7dc0e2",
        "activity_name": "Convection demonstration",
        "grade": "VII",
        "chapter": "4. HEAT",
        "concept": "Demonstration of convection",
        "topic": "Convection",
        "quarter": 1.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "78264349-d285-4b97-bc4d-767bf14a5dab",
        "activity_name": "Hydrostatic Paradox",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Study of hydrostatic pressure",
        "topic": "Hydrostatic Pressure",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "7fc4bbe4-5478-48ed-b1e1-bce8eef7302f",
        "activity_name": "Effect of water column: glass jar",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Study of Pascal's law",
        "topic": "Pascal's Law",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "3ded13fe-a9e9-4c06-aa7c-5516d5b34705",
        "activity_name": "Homemade Helicopter",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Making a hand made helicopter with the help of a thick paper and a straw",
        "topic": "Bernoulli's effect",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "fb85d3c2-401c-497d-be71-2200fca9104c",
        "activity_name": "Boat race using surface tension",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "making small boat like looking paper cuttings and race them by putting a little soap on them in the rear end and then let the surface tension make them move really fast",
        "topic": "Surface Tension",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "41dd1769-7656-4197-8ef8-bb55009b014f",
        "activity_name": "Homemade Boat",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "there are some variations: elasticity powered , air pressure, motor powered an with sails",
        "topic": "Buoyancy",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "d6ffe276-d95b-433a-aff3-94262c9879c0",
        "activity_name": "Bubble Fun",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "making large bubbles with the help of rope and two large sticks an the solution made of corn syrup,corn starch an dishwashing liquid",
        "topic": "Surface tension",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "35af91b7-4c1c-4859-b67c-e390801fdace",
        "activity_name": "Making a plane with paper cylinder",
        "grade": "VI",
        "chapter": "15. AIR AROUND US",
        "concept": "making a paper plane which is cylindrical in shape",
        "topic": "Pressure:Bernoullis principle",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "867e531c-9814-416a-8d60-fb11d9996754",
        "activity_name": "Air contains Oxygen",
        "grade": "VI",
        "chapter": "15. AIR AROUND US",
        "concept": "Detection of Oxygen as one of the gases present in air.",
        "topic": "Components of air",
        "quarter": 4.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "167bb6e0-1456-4608-b7c2-71242529304b",
        "activity_name": "Air contains Dust",
        "grade": "VI",
        "chapter": "15. AIR AROUND US",
        "concept": "Detection of dust as one of the gases present in air.",
        "topic": "Components of air",
        "quarter": 4.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "7dff6906-14e6-4321-a61b-59317a057630",
        "activity_name": "Vortex inside water",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "making small boat like looking paper cuttings and race them by putting a little soap on them in the rear end and then let the surface tension make them move really fast",
        "topic": "Vortex",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "2a7d4d66-ee45-4590-af17-731a3758be82",
        "activity_name": "Balloon Hovercraft",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "making a hovercraft with the help of a cd an an inflated balloon",
        "topic": "Pressure",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "8304ed62-7404-47c4-aa6e-4d06601954e6",
        "activity_name": "Crazy Bubbles",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "making bubbles of different shapes",
        "topic": "Surface Tension",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "e796aa2f-384b-4726-a8ab-6620fa44fa54",
        "activity_name": "Buoyancy demonstration with household items",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "various activities in our daily life which are only able due to atmospheric pressure such as working of syringes",
        "topic": "Buoyancy",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "1e500822-ed3d-4507-95e2-6babbf0aef8e",
        "activity_name": "Anemometer",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "it is an instrument to measure the direction and speed of winds",
        "topic": "Measuring wind speed",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "c459f7a0-9fe3-41bd-af1f-cf4a6b9502e9",
        "activity_name": "Making a boat",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "building different models of boats which work on different concepts",
        "topic": "Bernoulli's effect",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "c6a16df4-67ec-464d-b71c-e07e331a4291",
        "activity_name": "Helicopters with balloons",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "explaining how the helicopters fly",
        "topic": "Bernoulli's effect",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "c784ca64-68f7-45c9-97c7-55232936be85",
        "activity_name": "Balloons & lungs capacity",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "you can inflate the big polythene bag by blowing slowly due to bernoulli principle",
        "topic": "Bernoulli's effect",
        "quarter": 3.0,
        "type": "C",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "06ec87f2-ba92-4fa0-9356-fdb10b161a51",
        "activity_name": "Water spray using Bernoulli principle",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "making the chocolate milk spray by putting a small straw in the milk an the placing a small piece of straw on it an blowing in it the chocolate milk will come up and spray because of bernoulli principle",
        "topic": "Bernoulli's effect",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "d47179a3-4066-4252-8d50-e644a9262ef4",
        "activity_name": "Egg inside a bottle",
        "grade": "VIII",
        "chapter": "11. FORCE AND PRESSURE",
        "concept": "1. boil an egg and try to put it in a bottle with small hole than the egg.\n 2. now burn a piece of paper and put the egg on top of the bottle the egg is now sucked in the bottle",
        "topic": "Air pressure",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "89c0d9f5-8b13-4b7e-bb63-2e5ba39e9aef",
        "activity_name": "Blowing paper ball inside a bottle",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Study of Bernoulli's effect by blowing a paper ball into a bottle",
        "topic": "Bernoulli's effect and air pressure",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "42553cc3-d49e-4e8c-b72f-b77d2bae0f8b",
        "activity_name": "Vacuum Cannon",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Fun with vacuum cannon",
        "topic": "Air pressure",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "9da68fb1-d427-4142-aff5-16f3313c3cae",
        "activity_name": "Compressed Air gun",
        "grade": "IX",
        "chapter": "10. GRAVITATION",
        "concept": "Study of Air pressure using Compressed air gun",
        "topic": "Air Pressure",
        "quarter": 3.0,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "d9ee56b8-6077-44dd-b698-89a23c7699c4",
        "activity_name": "Terminal velocity demonstration",
        "grade": "XI",
        "chapter": "10. MECHANICAL PROPERTIES OF FLUIDS",
        "concept": "Study of terminal velocity",
        "topic": "Viscous force",
        "quarter": 3.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "c2320de5-1813-49f1-967d-7153f1a532ad",
        "activity_name": "Convection current: Air",
        "grade": "VII",
        "chapter": "4. HEAT",
        "concept": "Study of convection current produced in air",
        "topic": "Convection",
        "quarter": 1.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "5baaf33b-f9f0-4aec-a5d7-d3bf8cbca45a",
        "activity_name": "Brownian motion Study",
        "grade": "XI",
        "chapter": "13. KINETIC THEORY",
        "concept": "Study of Brownian motion",
        "topic": "Browninan motion",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "fb9e112e-4e17-42ea-999b-6139f2a3e781",
        "activity_name": "Bug walk",
        "grade": "VI",
        "chapter": "MISCELLANEOUS",
        "concept": "A fan that turns by wind makes a bug made from wire move,.",
        "topic": "Ashesive force",
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "Fluids and Pressure"
    },
    {
        "uuid": "48e5e088-9316-454d-8c4b-bc4e71511689",
        "activity_name": "Design Phase - Sun, Earth, Moon Model",
        "grade": "VIII",
        "chapter": "17. Stars and the Solar system",
        "concept": null,
        "topic": null,
        "quarter": 4.0,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "15fb4360-bc9a-40ca-a623-e8a660377d7f",
        "activity_name": "Extra Month of Hindu Calendar",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "7c037404-43c2-42d2-9ced-22e15b1770a3",
        "activity_name": "Working Model - Sun, Earth and Moon",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "6aa63e81-be54-47a9-9521-4a1c1efa92a3",
        "activity_name": "Phases of moon - working model",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "ab747710-7a52-44f9-a250-8748e7ce5260",
        "activity_name": "Zero Shadow Day Activity",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "1aa4472a-f920-418f-982b-50be61d8be41",
        "activity_name": "National Solar Calendar of India - Overview with Model",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "d6792f91-d5b9-46cb-a6d2-1f9a86200d42",
        "activity_name": "Gear Basics",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "c07b49c4-db22-4907-87e7-3693797de664",
        "activity_name": "Gears with everyday things",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "8873349a-f4de-42c9-896d-6c2f551a2313",
        "activity_name": "Volume of Cone, Cylinder and Sphere - 3D Printing in Mathematics",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "c98a7f19-ca53-4a66-b1b2-70771a06584a",
        "activity_name": "Centerline tool with Parallelogram",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "375920db-45a9-4c75-a32d-ffeebdbc141b",
        "activity_name": "Triangle for surface levelling ?",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "a8111b6c-af38-4646-99a9-4c9103a03e7a",
        "activity_name": "Resonating Pendulums",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "89220f7c-863a-43d9-b3ca-7278c3ac77ea",
        "activity_name": "Model to understand Trigonometric ratios",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "90b634ac-dc51-496d-b6f3-df190849b788",
        "activity_name": "Tracing oscillatory motion on paper",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "8f5e1f94-2ec5-4eee-b327-f73aed965907",
        "activity_name": "Find your blind spot",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "3b9b0c4f-d702-4898-8c0a-b55fc664cf71",
        "activity_name": "Pinhole Camera - Ray Path with Model",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "2fd255f3-99e4-43c4-b6ac-15e6ea2974bf",
        "activity_name": "Solar Eclipse explained with Model",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "775888ea-4cbd-4145-9c1d-e72e6529190f",
        "activity_name": "Pulley with everyday things",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "0860eafa-ba6c-49ba-b0de-5d7ac600b9eb",
        "activity_name": "Screw - Working Model",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "4f976284-a0fc-42af-83e6-5f25a8ab4eab",
        "activity_name": "Coil pump",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "2c739754-e35e-4855-9ac5-93d0d9cb4bf2",
        "activity_name": "Archimedes' screw - Working model",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "6434604f-be5e-4c3b-a439-3c4c85633888",
        "activity_name": "Umbra and Penumbra - Lunar and Solar Eclipse",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "fa07056f-a059-4ae7-bdf1-17842d43b6fc",
        "activity_name": "Pulley Ratio",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "0d9f606b-12d4-4445-81ee-654c5ef87019",
        "activity_name": "Hydraulic Power Generation model",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "6b2fd122-5725-492f-8d50-1ea99775f1b1",
        "activity_name": "Quantify Forces in interaction",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "b4ba3534-8aea-4a93-a5ea-f3cbed4a5890",
        "activity_name": "Components of Force",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "ac348d12-530b-40fb-8fcf-ed3ea0d655a5",
        "activity_name": "Forces In Equilibrium",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "dee4af5a-8a7e-458f-ad94-50758c21c479",
        "activity_name": "Visualize Light Path In Periscope",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "0be96eba-5faf-454b-8431-ec2fce687311",
        "activity_name": "rectilinear propagation of light",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "c41808f7-22a4-4963-8105-a09872b26e42",
        "activity_name": "fun with plane mirrors",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "73219347-edd8-41ed-9c5a-581a6ad9aa80",
        "activity_name": "Conductivity of heat and current",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "34d3177e-d643-4c57-a693-209bf5df3528",
        "activity_name": "Make a simple switch and use it in the circuit",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "574a43c1-093b-4ff3-b704-a2cbb07db8b8",
        "activity_name": "Simple model of Torch",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "bf23b2d8-f102-46f6-9fe8-2f42bb050e18",
        "activity_name": "To make a magnet by single touch method",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "2187b8d7-ce23-4cda-9108-32ceddb11ef8",
        "activity_name": "To identify magnetic and non-magnetic material",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "63c544cc-1156-4a18-b974-ef762a81f940",
        "activity_name": "To show that magnetic force is maximum at the ends of a magnet",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "5dc7f0e9-2bf8-4364-9ad2-d3db290ae37e",
        "activity_name": "To make a simple magnetic compass",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "f5634b66-9f77-4874-9ab4-4ce52e938b7c",
        "activity_name": "To show that a surface or a screen is needed behind the object to obtain the shadow of an object",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "cc311117-7dcb-4b59-848f-32daa0302abf",
        "activity_name": "Distance-Time graph ",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "b49f34d6-9ced-47fb-9f83-17e251e9527f",
        "activity_name": "Newton's disc",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "8da3adfe-59d4-4f33-8afa-760ea63d8248",
        "activity_name": "To show that light travels in a straight line (VII)",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "b9b81f9e-7203-4140-b4dc-e72322dbb3e5",
        "activity_name": "To measure the temperature of hot water by using laboratory thermometer",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "3b43f22a-7deb-4f8b-a6ee-8cf52da95465",
        "activity_name": "Transfer of heat by conduction",
        "grade": null,
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "G",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "7bf25f60-ec17-4a7b-bc8f-977d85582fb4",
        "activity_name": "Test for starch",
        "grade": "VI",
        "chapter": null,
        "concept": "A qualitative study of the iodine test for starch.",
        "topic": "Nutrition",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "db9f8101-444a-477a-8274-e87f37b2045c",
        "activity_name": "Test for protein",
        "grade": "VI",
        "chapter": null,
        "concept": "The study of a conclusive test for protein.",
        "topic": "Nutrition",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "64073c0c-632f-47e7-ab13-e6846489db76",
        "activity_name": "Test for Fat",
        "grade": "VI",
        "chapter": null,
        "concept": "The study of a conclusive test for fat.",
        "topic": "Nutrition",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "5fc3d69c-2122-4cd6-ad24-4351e8a41c20",
        "activity_name": "Carbohydrate test",
        "grade": "VI",
        "chapter": null,
        "concept": "The study of a conclusive test for carbohydrate.",
        "topic": "Nutrition",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "ba5eb636-103b-4822-b39f-98be40b4e88e",
        "activity_name": "Cleaning of water using Alum",
        "grade": "VI",
        "chapter": null,
        "concept": "A comprehensive study of water purification using Alum.",
        "topic": "Method of separation",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "a8121a23-f190-400c-903a-4c283d721a7d",
        "activity_name": "Separations of two components of mixture by sedimentation and decantation.",
        "grade": "VI",
        "chapter": null,
        "concept": "A comprehensive study of the process of sedimentation. ",
        "topic": "Sedimentation,Decantation,Filtration",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "12e8f0cb-c724-4ed6-a4e3-5310d5cb50b3",
        "activity_name": "Demonstration of evaporation and condensation using salt water",
        "grade": "VI",
        "chapter": null,
        "concept": "A comprehensive study of the process of evaporation.",
        "topic": "Evaporation",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "949e1ece-119c-4d4e-8ce1-e45ba1821108",
        "activity_name": "Water filtration",
        "grade": "VI",
        "chapter": null,
        "concept": "A comprehensive study of the process of water filtration.",
        "topic": "Method of separation",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "529e9076-dded-48fb-bc81-3cc25d598bc0",
        "activity_name": "Condensation",
        "grade": "VI",
        "chapter": null,
        "concept": "A comprehensive study of the process of condensation. ",
        "topic": "Method of separation",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "4fd591d6-cb68-420e-9310-8d3380e252d6",
        "activity_name": "Water purification using charcoal",
        "grade": "VI",
        "chapter": null,
        "concept": "A comprehensive study of the process of water purification using charcoal. ",
        "topic": "Method of separation",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "f7d2832a-a207-44b0-a0d1-e69ddc4f881d",
        "activity_name": "Leaf can respire and presence of stomata",
        "grade": "VI",
        "chapter": null,
        "concept": " A comprehensive study of the process of respiration by leaves. ",
        "topic": "Leaf",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "395c6232-db4b-4dfb-a62f-1b9b41dc17ed",
        "activity_name": "Transpiration in plants",
        "grade": "VI",
        "chapter": null,
        "concept": "A comprehensive study of the process of transpiration in plants.",
        "topic": "Leaf",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "95fe9c09-2c8e-4972-8902-3f19d3a9d667",
        "activity_name": "Leaf contains starch",
        "grade": "VI",
        "chapter": null,
        "concept": "A comprehensive study of a test for starch present in leaves. ",
        "topic": "Leaf",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "8f4ecc2c-e0dc-4e4d-bdc9-8f827dd8f964",
        "activity_name": "Sieving: Separate the constituent of a mixture containing sand and sugar",
        "grade": "VI",
        "chapter": null,
        "concept": "Students will be given sieves of different sizes and mixture of different materials. They will separate them using sieve.",
        "topic": "Methods of separation",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "e754ab1d-f160-4226-8bd7-9315bd2c0462",
        "activity_name": "Secret message",
        "grade": "VII",
        "chapter": null,
        "concept": "Use cotton bud as pen and lemon juice as ink to write something on a paper. Wait till the text disappears and then heat the paper to see the text again.",
        "topic": "Chemical changes",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "8dcda2a9-c78e-493f-baf5-7483b7d4faf7",
        "activity_name": "Volcano",
        "grade": "VII",
        "chapter": null,
        "concept": "A VOLCANO is produced over thousands of years as heat and pressure build up. That aspect of a volcano is very difficult to recreate in a home experiment. However this volcano will give you an idea of what it might look like when a volcano erupts flowing lava. This is a classic experiment in which a CHEMICAL reaction can create the appearance of a PHYSICAL volcano eruption. You should look at pictures of volcanoes to be familiar with the different types. (A SHIELD volcano, for example is the most common kind of volcano, and yet few people know about them) The reaction will bubble up and flow down the side like a real volcano (only much faster!) Look for videos of volcanoes erupting and be sure that you understand how heat and pressure work to really make volcanoes erupt.",
        "topic": "Chemical change",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "83b6988c-4756-4e42-8e29-95ee0b5f28dc",
        "activity_name": "Chemical Change Making Oxygen Gas",
        "grade": "VII",
        "chapter": null,
        "concept": "Yeast with hydrogen peroxide makes oxygen. Blow the produced gas to a flame. The flame becomes brighter with the gas. This is a test for the oxygen.",
        "topic": "Chemical change",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "508cb920-403f-4adb-b0d8-7bd2ed97e0b3",
        "activity_name": "Anaerobic fermentation with balloons",
        "grade": "VII",
        "chapter": null,
        "concept": "Example of two reactions. One is slow and one is fast. Slow one is yeast in sugar water and fast one is baking soda with water.",
        "topic": "Chemical change",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "8e222242-4eb8-4a3d-a1eb-07ba191be1fc",
        "activity_name": "Chemical Change Making a New Gas",
        "grade": "VII",
        "chapter": null,
        "concept": "Burning candle in presence of vinegar+baking soda. It produces CO2 and the flame goes out.",
        "topic": "Chemical change",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "2e6a0e4a-99b1-463b-8e87-21ccc402555a",
        "activity_name": "CO2 is exhaled during respiration",
        "grade": "VII",
        "chapter": null,
        "concept": "Blow into lime water and see if it becomes milky.",
        "topic": "Breathing",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "e38f5542-30fa-416e-b7d9-6eacc0c10982",
        "activity_name": "Effect of saliva on starch",
        "grade": "VII",
        "chapter": null,
        "concept": "To see the effect of saliva on starch",
        "topic": "Digestion in humans",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "b60651cb-31cc-4b51-ba93-386c3129cab6",
        "activity_name": "CO2 is liberated during respiration",
        "grade": "VII",
        "chapter": null,
        "concept": "A comprehensive study of the test that shows the production of CO2 during respiration. ",
        "topic": "Respiration",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "64829122-fae8-4358-925b-67443796d75f",
        "activity_name": "O2 is liberated during photosynthesis",
        "grade": "VII",
        "chapter": null,
        "concept": "A comprehensive study of the test that shows the production of O2 during photosynthesis.",
        "topic": "Photosynthesis",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "f9ee096d-12de-4176-87b9-bced1b01dc27",
        "activity_name": "CO2 is essential for photosynthesis",
        "grade": "VII",
        "chapter": null,
        "concept": "To verify that CO2 is essential for photosynthesis",
        "topic": "Photosynthesis",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "a8a070f5-469e-4fd2-a4bd-06bd0b7b94af",
        "activity_name": "Presence of stomata",
        "grade": "VII",
        "chapter": null,
        "concept": "To demonstrate the presence of stomata ",
        "topic": "Photosynthesis",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "98e34774-09b7-4a4b-9cd9-a676700312b9",
        "activity_name": "Purity test of Honey",
        "grade": "VII",
        "chapter": null,
        "concept": "To test the purity of honey",
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "0ef0e1aa-8511-42c1-b629-eb84968c1674",
        "activity_name": "Litmus test",
        "grade": "VII",
        "chapter": null,
        "concept": "To use Litmus as an indicator ",
        "topic": "Adulteration",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "ea99c232-66bb-4d85-8f2a-3f202ab6ca5e",
        "activity_name": "Use turmeric as an indicator",
        "grade": "VII",
        "chapter": null,
        "concept": "To use turmeric as a natural indicator ",
        "topic": "Natural indicators around us",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "e613c608-bf72-4b1a-b1b9-d062833ff84f",
        "activity_name": "Using china rose as an indicator",
        "grade": "VII",
        "chapter": null,
        "concept": "To use china rose as a natural indicator ",
        "topic": "Natural indicators around us",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "5ff85046-d2b1-4b63-a3a3-338c560e3945",
        "activity_name": "Neutralization",
        "grade": "VII",
        "chapter": null,
        "concept": "To perform a neutralisation reaction",
        "topic": "Natural indicators around us",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "f234f9bd-8b01-4488-b0c0-fba152edb924",
        "activity_name": "Cleaning of coin",
        "grade": "VII",
        "chapter": null,
        "concept": "To clean an old coin using a chemical reaction ",
        "topic": "Neutralization",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "98e3b502-e20d-4b46-8c46-3d0cbda418b5",
        "activity_name": "Limestone check",
        "grade": "VII",
        "chapter": null,
        "concept": "To distinguish limestone from other stones using chemical reaction",
        "topic": "Acid,Base,Salt",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "ba5bbc20-ae72-49e3-8e77-a6dd68a3c83f",
        "activity_name": "Lava Lamp",
        "grade": "VII",
        "chapter": null,
        "concept": "To create a lava lamp",
        "topic": "Acid,Base,Salt",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "a6266fb5-692b-4f70-ad08-5a6ea8b667b8",
        "activity_name": "Burning of sulphur powder",
        "grade": "VII",
        "chapter": null,
        "concept": "To observe the burning of sulphur powder",
        "topic": "Acid,Base,Salt",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "0ea987ff-a6a0-4c6a-bb60-2e2a4174c84c",
        "activity_name": "Fun bubbles",
        "grade": "VII",
        "chapter": null,
        "concept": "To create fun bubbles using common items",
        "topic": "Acid,Base,Salt",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "7a102559-5a74-47b4-99e8-a4f2ba07d464",
        "activity_name": "Carbon Snake",
        "grade": "VII",
        "chapter": null,
        "concept": "Making a carbon snake using chemistry",
        "topic": "Cell",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "4b78d1c4-123a-48fd-96eb-93b3a46b19cb",
        "activity_name": "Chameleon four colour change reaction",
        "grade": "VII",
        "chapter": null,
        "concept": "To show chameleon four colour change reaction",
        "topic": "Chemical reactions with acid ",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "a7cb4e73-c500-48a9-9dfb-6ef14b28879f",
        "activity_name": "Elephant toothpaste",
        "grade": "VII",
        "chapter": null,
        "concept": "To create elephant toothpaste",
        "topic": "Chemical reactions with acid ",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "568dc066-6693-44fd-a5fc-c48c30387c51",
        "activity_name": "Floating boat",
        "grade": "VII",
        "chapter": null,
        "concept": "To push a boat using chemical reactions",
        "topic": "Chemical reaction with base",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "4f4153bc-f2e0-42f8-a90d-0ab550d2454d",
        "activity_name": "Egg in vinegar",
        "grade": "VII",
        "chapter": null,
        "concept": "To see the inside cell structure of an egg using vinegar",
        "topic": "Physical and chemical changes",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "8a01f0c6-0c4e-43f3-9b22-4d3c4774aa6b",
        "activity_name": "Bleeding paper experiment",
        "grade": "VII",
        "chapter": null,
        "concept": "To observe the reaction of turmeric and acid",
        "topic": "Reaction with acid and base",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "c708e051-1536-44f5-b8d6-b65218684ccd",
        "activity_name": "Reaction of limestone with water",
        "grade": "VII",
        "chapter": null,
        "concept": "To demonstrate the reaction of limestone and water ",
        "topic": "Chemical reaction with acid",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "13b2711b-4d20-49e4-b1ce-98c04bd7e06d",
        "activity_name": "Measuring heat energy",
        "grade": "VII",
        "chapter": null,
        "concept": "A comprehensive study of measuring heat energy by observing temperature change.",
        "topic": "chemical reaction with acid and base",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "01e0137e-665c-4d30-9853-b3a1e0fedf8c",
        "activity_name": "Matter Cannot Be Destroyed",
        "grade": "VII",
        "chapter": null,
        "concept": "To show that matter cannot be destroyed",
        "topic": "Chemical reaction with limestone and water",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "870bd395-53da-456a-9f67-968bacae1df3",
        "activity_name": "Water cycle bag",
        "grade": "VII",
        "chapter": null,
        "concept": "One of the easiest science experiments for students is a simple water cycle bag experiment. This Water Cycle Bag Experiment is a great way to introduce them to science and all the ideas of the water cycle.",
        "topic": "Measuring Temperature",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "b34ac6b7-20ea-45d6-84a6-57459f92a69d",
        "activity_name": "tin can compression",
        "grade": "VII",
        "chapter": null,
        "concept": "This classic textbook experiment of crashing a tin can will illustrate  about how pressure of air from all directions can crash a tin can.",
        "topic": "Physical changes",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "69b0f982-d035-4ee7-b63c-07713277c637",
        "activity_name": "Expansion of air in a bottle put a balloon on top and heat the bottle",
        "grade": "VII",
        "chapter": null,
        "concept": "High-speed wind is accompanied by reduced air pressure. This theory is properly justified in this experiment where students will try to insert a piece of paper placed at the neck of a bottle by blowing at different speeds and observe the results.",
        "topic": "climate",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "29eb804f-11e7-4c6d-9363-cd721d899c27",
        "activity_name": "Bottle Cap rocket",
        "grade": "VII",
        "chapter": null,
        "concept": "\"Air expands on heating\" is the theory behind the simple experiment consisting of a bottle, a balloon, and a heat source. Put the balloon on the mouth of the bottle and heat it from the bottom end to see the result and analyze why.",
        "topic": "Air exerts pressure",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "309a33bd-a5c8-4e8f-b72d-fd5d9bf28506",
        "activity_name": "Hot rises cold sinks",
        "grade": "VII",
        "chapter": null,
        "concept": "In a mixture of differently coloured hot water and cold water, hot water rises.",
        "topic": "Air expands on heating",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "08b6eaf4-f801-4f49-81ce-06665244d072",
        "activity_name": "Heat rises cold sinks 2",
        "grade": "VII",
        "chapter": null,
        "concept": "A demonstration on hot rises and cold sinks to concrete the idea about the density and effect of density difference that forces hot liquids to rise like hot air and cold liquids to sink like cold air.",
        "topic": "Air exerts pressure",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "d2e0fb4c-1d9a-4030-b0a9-f1382d68c58e",
        "activity_name": "Convection current",
        "grade": "VII",
        "chapter": null,
        "concept": "Hot water in a fish tank to see convection current. Put a ice cube to see colored water going down imitating rain.",
        "topic": "Wind current are generated due to uneven heating on the earth",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "4a74a809-2579-4b8c-b6df-b3a1c073e821",
        "activity_name": "Waste-water treatment",
        "grade": "VII",
        "chapter": null,
        "concept": "To treat waste water using charcoal",
        "topic": "Wind current are generated due to uneven heating on the earth",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "32e5d8df-9a66-4265-ad97-b2bf30029c3d",
        "activity_name": "Water conduction through stem",
        "grade": "VII",
        "chapter": null,
        "concept": "To observe water conduction in a stem",
        "topic": "Water",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "9a0edf38-a90e-4449-a4cf-33de51094464",
        "activity_name": "To view the onion cell ",
        "grade": "VII",
        "chapter": null,
        "concept": "To view onion cells under a microscope",
        "topic": "Water",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "946d7bf2-1310-4a6e-8107-a2137e17a9c1",
        "activity_name": "Chemical Changes: Fast and Slow",
        "grade": "VII",
        "chapter": null,
        "concept": "To observe fast and slow chemical reactions",
        "topic": "Cell",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "e6d3e933-3a36-46ec-8982-29f5e8701fca",
        "activity_name": "Soil erosion experiment",
        "grade": "VIII",
        "chapter": null,
        "concept": "To demonstrate soil erosion",
        "topic": "Chemical changes",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "238ad731-26b0-4f65-b9d6-ba0e56020d5c",
        "activity_name": "Alcohol produced through Yeast",
        "grade": "VIII",
        "chapter": null,
        "concept": "To produce alcohol using yeast",
        "topic": "Preparation of soil",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "e3623baa-e64d-47f8-8f20-10e43bf3413c",
        "activity_name": "Identification of lactobacillus bacteria",
        "grade": "VIII",
        "chapter": null,
        "concept": "Identification of lactobacillus bacteria",
        "topic": "Microorganism",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "e9856f49-42dd-45c9-8ea8-b3ccdcd1289d",
        "activity_name": "Reaction of metals with acids",
        "grade": "VIII",
        "chapter": null,
        "concept": "Observe the reaction of acids with metals",
        "topic": "Microorganisms",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "362a0622-26f4-4b17-b0d1-a4e905d750e8",
        "activity_name": "CuSO4 reaction with iron",
        "grade": "VIII",
        "chapter": null,
        "concept": "To see the effect of copper sulphate reacting with iron",
        "topic": "Chemical properties of metals & Non-metals",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "d580292a-b200-441a-9160-d9b62d095836",
        "activity_name": "Sodium reacts with water",
        "grade": "VIII",
        "chapter": null,
        "concept": "To see the effect of sodium reacting with water",
        "topic": "Reaction of copper with iron",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "084fde27-63f7-422f-b4ea-52ab9beb5917",
        "activity_name": "Testing the nature of rust",
        "grade": "VIII",
        "chapter": null,
        "concept": "To test the nature of rust",
        "topic": "Reaction with water",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "4fcbe02c-c321-4a5f-a4c9-7cde27b86466",
        "activity_name": "Reaction of Sodium Hydroxide with Aluminum",
        "grade": "VIII",
        "chapter": null,
        "concept": "To see the reaction of NaOH with Aluminum",
        "topic": "Chemical properties of metals & Non-metals",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "48a4e54e-0380-4eba-8fa8-9c89965a62d5",
        "activity_name": "Burning Magnesium ribbon",
        "grade": "VIII",
        "chapter": null,
        "concept": "To observe the burning of magnesium ribbon",
        "topic": "Chemical properties of metals & Non-metals",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "5b177b22-877a-4c31-9c11-8697f5716b39",
        "activity_name": "DNA extraction",
        "grade": "VIII",
        "chapter": null,
        "concept": "To extract DNA from banana",
        "topic": "Physical Properties of Metals and Non-Metals",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "13ddd392-9fce-47e9-8e37-d04559b4db1a",
        "activity_name": "Conductivity of heat and current",
        "grade": "VIII",
        "chapter": null,
        "concept": "Complete the given circuit with the help of given pieces of materials. See in which cases the bulb glows and in which cases it does not.",
        "topic": "Changes around us",
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "New Addition"
    },
    {
        "uuid": "45377378-06d9-41a2-bb31-6030df69d70b",
        "activity_name": "Expansion of metal",
        "grade": "VIII",
        "chapter": null,
        "concept": "There is metal ring and a rod. Radius of sphere is slightly bigger than that of ring so that the sphere can not pass through the ring. If you heat the ring it will expand and now the sphere can pass.",
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "67d99ec6-ddf6-4fdb-891e-d6e27aa35940",
        "activity_name": "Traffic lights using chemistry",
        "grade": "VIII",
        "chapter": null,
        "concept": " a chemical reaction  by Dextrose(D-glucose) and potassium hydroxide in the presence of a special indicator changes the color of the solution from green to yellow to red while shaking it and back to green when put in rest, which reminds of a traffic light signal. ",
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "ff937b80-c0a6-4529-aab0-8bcb503c473f",
        "activity_name": "Test of Urea in Urine",
        "grade": "VIII",
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "13b3b96f-344b-4c26-975b-6fc0ca953895",
        "activity_name": "Test for bile Salt in urine",
        "grade": "VIII",
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "07744e55-88c7-4d84-8933-bf255a4bc5c1",
        "activity_name": "Mechanism of breathing",
        "grade": "VIII",
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "639604c2-8a92-4ad5-855c-65e982aed5ca",
        "activity_name": "Working Model of Heart",
        "grade": "VIII",
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "cef77d4e-69f4-4df7-ac4b-3aec67449c37",
        "activity_name": "Working model of kidney",
        "grade": "VIII",
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "0253ed19-9936-433f-94ac-ae053a92a0d7",
        "activity_name": "Light is necessary for photosynthesis",
        "grade": "VIII",
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "d9dd5d68-8324-49b9-86b8-644edda47630",
        "activity_name": "Slide preparation of Mitosis",
        "grade": "VIII",
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "27d83d3a-dcf3-4ab8-8571-7dbcba45a990",
        "activity_name": "Activity to show Blood Groups and Rh Factor",
        "grade": "VIII",
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "1ef6888c-654d-4b7e-a981-8943dcef4270",
        "activity_name": "Study of Blood Cells",
        "grade": "VIII",
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "355025d3-7455-4436-8e18-d6e911d5e047",
        "activity_name": "Activity related to Biotechnology, PCR and Electrophoresis",
        "grade": "VIII",
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "99e63829-025c-486a-9685-46cef043ef4b",
        "activity_name": "Effect of temperature on solubility",
        "grade": "VIII",
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "85808c98-9d32-431c-a055-0b1eda4a35bf",
        "activity_name": "Separating a mixture of two immiscible liquids",
        "grade": "VIII",
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "95f587b8-d15b-48be-992f-82d6e4c9c132",
        "activity_name": "To observe the response of shoot towards light",
        "grade": "VIII",
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "2a3be707-c85f-496d-91df-c0514a1be6a5",
        "activity_name": "To obtain pure CuSO4 crystals from CuSO4 powder",
        "grade": "VIII",
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    },
    {
        "uuid": "ac5041d9-624f-4821-9888-02ddffef6ecd",
        "activity_name": "To demonstrate transport of water through xylem",
        "grade": "VIII",
        "chapter": null,
        "concept": null,
        "topic": null,
        "quarter": null,
        "type": "D",
        "aidance": null,
        "space": null,
        "super_topic": "Bio Chem"
    }
]