import { memo, SVGProps } from 'react';

const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.15312 0.76875C4.9125 0.1875 4.27812 -0.121875 3.67188 0.0437501L0.921875 0.79375C0.378125 0.94375 0 1.4375 0 2C0 9.73125 6.26875 16 14 16C14.5625 16 15.0563 15.6219 15.2063 15.0781L15.9563 12.3281C16.1219 11.7219 15.8125 11.0875 15.2312 10.8469L12.2312 9.59688C11.7219 9.38438 11.1313 9.53125 10.7844 9.95938L9.52188 11.5C7.32188 10.4594 5.54062 8.67813 4.5 6.47813L6.04063 5.21875C6.46875 4.86875 6.61562 4.28125 6.40312 3.77187L5.15312 0.771875V0.76875Z'
      fill='#F59E0B'
    />
  </svg>
);

const Memo = memo(SvgIcon);
export { Memo as SvgIcon };
