import { activity_list } from "../../static/activity_list";
import React, { useState } from "react";
import Select from "react-select"; // Import react-select
import { FaShoppingCart, FaFilter } from "react-icons/fa";
import classes from "./Product.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";

const backend_url = process.env.REACT_APP_API_BASE_URL;

function Product() {
    const [selectedGrades, setSelectedGrades] = useState<any>([]);
    const [selectedTopics, setSelectedTopics] = useState<any>([]);
    const [selectedQuarters, setSelectedQuarters] = useState<any>([]);
    const [selectedChapters, setSelectedChapters] = useState<any>([]);
    const [selectedSuperTopic, setSelectedSuperTopics] = useState<any>([]);
    const [cart, setCart] = useState<any>({});
    const [isCartOpen, setIsCartOpen] = useState<boolean>(false); // State to manage cart popup
    const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState(null); // State to track the selected image
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const isLoggedIn = localStorage.getItem("accessToken") ? true : false;

    const navigate = useNavigate();

    const openImagePopup = (imageUrl) => {
        setSelectedImage(imageUrl);
    };

    const closeImagePopup = () => {
        setSelectedImage(null);
    };

    const uniqueGrades = [
        ...new Set(activity_list.map((activity) => activity.grade)),
    ];
    const uniqueTopics = [
        ...new Set(activity_list.map((activity) => activity.topic)),
    ];
    const uniqueQuarters = [
        ...new Set(activity_list.map((activity) => activity.quarter)),
    ];
    const uniqueChapters = [
        ...new Set(activity_list.map((activity) => activity.chapter)),
    ];
    const uniqueSuperTopics = [
        ...new Set(activity_list.map((activity) => activity.super_topic)),
    ];

    const toSelectOptions = (items) =>
        items.map((item) => ({ label: item, value: item }));

    const filteredActivities = activity_list.filter((activity) => {
        return (
            (!selectedGrades.length ||
                selectedGrades.some(
                    (grade) => grade.value === activity.grade
                )) &&
            (!selectedTopics.length ||
                selectedTopics.some(
                    (topic) => topic.value === activity.topic
                )) &&
            (!selectedQuarters.length ||
                selectedQuarters.some(
                    (quarter) => Number(quarter.value) === activity.quarter
                )) &&
            (!selectedChapters.length ||
                selectedChapters.some(
                    (chapter) => chapter.value === activity.chapter
                )) &&
            (!selectedSuperTopic.length ||
                selectedSuperTopic.some(
                    (topic) => topic.value === activity.super_topic
                ))
        );
    });

    const updateCart = (activity, quantity) => {
        const updatedQuantity = Math.max(0, quantity);
        setCart((prevCart) => {
            const newCart = { ...prevCart };
            if (updatedQuantity === 0) {
                delete newCart[activity.uuid];
            } else {
                newCart[activity.uuid] = {
                    ...activity,
                    quantity: updatedQuantity,
                };
            }
            return newCart;
        });
    };

    const clearCart = () => {
        setCart({});
    };

    // Calculate the number of unique items in the cart
    const uniqueItemCount = Object.keys(cart).length;

    const groupedActivities = filteredActivities.reduce((groups, activity) => {
        const { super_topic } = activity;
        if (!groups[super_topic]) groups[super_topic] = [];
        groups[super_topic].push(activity);
        return groups;
    }, {});

    const [openSections, setOpenSections] = useState(
        Object.fromEntries(
            Object.keys(groupedActivities).map((superTopic) => [
                superTopic,
                true,
            ])
        )
    );

    const toggleSection = (superTopic) => {
        setOpenSections((prev) => ({
            ...prev,
            [superTopic]: !prev[superTopic],
        }));
    };

    const submitCart = async () => {
        const loadingToast = toast.loading("Submitting Cart...");
        const cartItemsArray = Object.values(cart).map((cartItem: any) => ({
            uuid: cartItem.uuid,
            activity_name: cartItem.activity_name,
            quantity: cartItem.quantity,
        }));

        console.log("Cart Submitted:", cartItemsArray);

        let token = localStorage.getItem("accessToken");
        if (!token) {
            toast.error("Please Login To Submit.");
            await sleep(760);
            toast.dismiss();
            navigate("/login");
            return;
        }

        let submit_url = `${backend_url}/api/cart/submit-cart`;

        try {
            let response = await fetch(submit_url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Add the token to the Authorization header
                },
                body: JSON.stringify(cartItemsArray),
            });

            response = await response.json();
            toast.success("Cart Submitted", { id: loadingToast });
            console.log(response, "----->");
            setIsCartOpen(false);
        } catch (error) {
            toast.error("Cart Failed To Submit", { id: loadingToast });
            console.error("Error submitting cart:", error);
        }
    };

    return (
        <div className="d-flex">
            <div className={classes.nav} style={{ display: "flex" }}>
                <Link to="/">
                    <div className={classes.logoPng}></div>
                </Link>
                <div className={classes.links} style={{ display: "flex" }}>
                    <Link to="/">
                        <div className={classes.link}>
                            <div className={classes.linkHome}>Home</div>
                        </div>
                    </Link>

                    <div className={classes.link2}>
                        <div className={classes.linkAbout}>About</div>
                    </div>
                    <Link to="/product">
                        <div className={classes.link3}>
                            <div className={classes.linkProducts}>Products</div>
                        </div>
                    </Link>

                    <div className={classes.link4}>
                        <div className={classes.linkEvents}>Events</div>
                    </div>
                    <div className={classes.link5}>
                        <div className={classes.linkContact}>Contact</div>
                    </div>
                </div>

                {!isLoggedIn ? (
                    <Link to="/login">
                        <button className={classes.button}>
                            <div className={classes.bookDemo}>Login</div>
                        </button>
                    </Link>
                ) : (
                    <button className={classes.button}>
                        <div className={classes.bookDemo}>Book Demo</div>
                    </button>
                )}
            </div>
            <div className="p-4">
                {/* <h1 className="text-3xl font-bold mb-4">Activity List</h1> */}
                {/* <img
                src="/jigyasu_logo.png"
                alt="jigyasu logo"
                className="mt-4 w-42 h-32 object-cover mb-2 cursor-pointer"
                onClick={() => {
                    window.location.reload();
                }}
            /> */}

                <div className="p-6">
                    {/* Desktop Filters */}
                    <div className="hidden md:flex gap-4 mb-6">
                        <Select
                            id="grade-select"
                            isMulti
                            options={toSelectOptions(uniqueGrades)}
                            onChange={(selectedOptions) =>
                                setSelectedGrades(selectedOptions || [])
                            }
                            value={selectedGrades}
                            className="w-1/4"
                            placeholder="Filter by Grade"
                        />
                        <Select
                            id="topic-select"
                            isMulti
                            options={toSelectOptions(uniqueTopics)}
                            onChange={(selectedOptions) =>
                                setSelectedTopics(selectedOptions || [])
                            }
                            value={selectedTopics}
                            className="w-1/4"
                            placeholder="Filter by Topic"
                        />
                        <Select
                            id="quarter-select"
                            isMulti
                            options={toSelectOptions(uniqueQuarters)}
                            onChange={(selectedOptions) =>
                                setSelectedQuarters(selectedOptions || [])
                            }
                            value={selectedQuarters}
                            className="w-1/4"
                            placeholder="Filter by Quarter"
                        />
                        <Select
                            id="chapter-select"
                            isMulti
                            options={toSelectOptions(uniqueChapters)}
                            onChange={(selectedOptions) =>
                                setSelectedChapters(selectedOptions || [])
                            }
                            value={selectedChapters}
                            className="w-1/4"
                            placeholder="Filter by Chapter"
                        />
                        <Select
                            id="supertopic-select"
                            isMulti
                            options={toSelectOptions(uniqueSuperTopics)}
                            onChange={(selectedOptions) =>
                                setSelectedSuperTopics(selectedOptions || [])
                            }
                            value={selectedSuperTopic}
                            className="w-1/4"
                            placeholder="Filter by SuperTopic"
                        />
                    </div>

                    {/* Filter Modal for Mobile */}
                    {isFilterModalOpen && (
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                            onClick={() => setIsFilterModalOpen(false)} // Close on outside click
                        >
                            <div
                                className="relative bg-white p-6 rounded-lg shadow-lg w-80"
                                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
                            >
                                <button
                                    onClick={() => setIsFilterModalOpen(false)}
                                    className="absolute top-2 right-4 text-gray-500 text-xl"
                                >
                                    &times; {/* This is the "X" icon */}
                                </button>
                                <h2 className="text-xl font-bold mb-4">
                                    Filters
                                </h2>

                                <Select
                                    isMulti
                                    options={toSelectOptions(uniqueGrades)}
                                    onChange={(selectedOptions) =>
                                        setSelectedGrades(selectedOptions || [])
                                    }
                                    value={selectedGrades}
                                    className="mb-4"
                                    placeholder="Filter by Grade"
                                />
                                <Select
                                    isMulti
                                    options={toSelectOptions(uniqueTopics)}
                                    onChange={(selectedOptions) =>
                                        setSelectedTopics(selectedOptions || [])
                                    }
                                    value={selectedTopics}
                                    className="mb-4"
                                    placeholder="Filter by Topic"
                                />
                                <Select
                                    isMulti
                                    options={toSelectOptions(uniqueQuarters)}
                                    onChange={(selectedOptions) =>
                                        setSelectedQuarters(
                                            selectedOptions || []
                                        )
                                    }
                                    value={selectedQuarters}
                                    className="mb-4"
                                    placeholder="Filter by Quarter"
                                />
                                <Select
                                    isMulti
                                    options={toSelectOptions(uniqueChapters)}
                                    onChange={(selectedOptions) =>
                                        setSelectedChapters(
                                            selectedOptions || []
                                        )
                                    }
                                    value={selectedChapters}
                                    className="mb-4"
                                    placeholder="Filter by Chapter"
                                />
                                <Select
                                    isMulti
                                    options={toSelectOptions(uniqueSuperTopics)}
                                    onChange={(selectedOptions) =>
                                        setSelectedSuperTopics(
                                            selectedOptions || []
                                        )
                                    }
                                    value={selectedSuperTopic}
                                    className="mb-4"
                                    placeholder="Filter by SuperTopic"
                                />
                            </div>
                        </div>
                    )}
                </div>

                {/* Render filtered activities */}

                <div className="space-y-6">
                    {Object.entries(groupedActivities).map(
                        ([superTopic, activities]: [string, any[]]) => {
                            const isOpen = openSections[superTopic];

                            // Check if any activity from the current superTopic is in the cart
                            const isInCart = activities.some(
                                (activity) => cart[activity.uuid]?.quantity > 0
                            );

                            return (
                                <div
                                    key={superTopic}
                                    className="border rounded-lg shadow-md p-4"
                                >
                                    {/* Accordion Header */}
                                    <div
                                        className="flex flex-wrap justify-between items-center mb-4 mt-3 cursor-pointer ml-2"
                                        onClick={() =>
                                            toggleSection(superTopic)
                                        }
                                    >
                                        <div className="flex items-center space-x-4">
                                            <h2 className="text-xl font-semibold">
                                                {superTopic}
                                            </h2>

                                            {/* Chip with number of activities, displayed beside superTopic on desktop */}
                                            <span className=" md:inline-flex bg-gray-200 text-gray-800 px-2 py-1 rounded-full text-xs">
                                                Activites: {activities.length}
                                            </span>
                                        </div>

                                        <div className="flex items-center space-x-4 mr-4">
                                            {isOpen ? (
                                                <>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            activities.forEach(
                                                                (activity) => {
                                                                    updateCart(
                                                                        activity,
                                                                        (cart[
                                                                            activity
                                                                                .uuid
                                                                        ]
                                                                            ?.quantity ||
                                                                            0) +
                                                                            1
                                                                    );
                                                                }
                                                            );
                                                        }}
                                                        className="bg-blue-500 text-white px-3 py-1 rounded-lg text-sm hover:bg-blue-500 transition"
                                                    >
                                                        Add Section
                                                    </button>

                                                    {/* Conditionally render Remove Section from Cart if there are items in the cart */}
                                                    {isInCart && (
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevent accordion toggle on button click
                                                                activities.forEach(
                                                                    (
                                                                        activity
                                                                    ) =>
                                                                        updateCart(
                                                                            activity,
                                                                            0
                                                                        )
                                                                );
                                                            }}
                                                            className="bg-red-500 text-white px-3 py-1 rounded-lg text-sm hover:bg-red-500 transition ml-2"
                                                        >
                                                            Remove Section
                                                        </button>
                                                    )}

                                                    <button className="ml-2 text-gray-500 cursor-pointer text-lg">
                                                        &#9650;{" "}
                                                        {/* Up arrow when open */}
                                                    </button>
                                                </>
                                            ) : (
                                                <button className="text-gray-500 text-lg cursor-pointer mr-4">
                                                    &#9660;{" "}
                                                    {/* Down arrow when closed */}
                                                </button>
                                            )}
                                        </div>

                                        {/* Display number of activities below superTopic on mobile */}
                                        {/* <span className="md:hidden bg-gray-200 text-gray-800 px-2 py-1 rounded-full text-xs mt-2">
                                        Activites : {activities.length}
                                    </span> */}
                                    </div>

                                    {/* Accordion Content */}
                                    {isOpen && (
                                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                            {activities.map((activity) => {
                                                const cartItem =
                                                    cart[activity.uuid];
                                                return (
                                                    <div
                                                        key={activity.uuid}
                                                        className="flex flex-col p-3 border rounded-lg shadow-md h-full"
                                                    >
                                                        <div className="flex-grow">
                                                            <img
                                                                src={`/images/${activity.uuid}.png`}
                                                                alt={
                                                                    activity.activity_name
                                                                }
                                                                className="w-full h-32 object-cover mb-2 cursor-pointer"
                                                                onClick={() =>
                                                                    openImagePopup(
                                                                        `/images/${activity.uuid}.png`
                                                                    )
                                                                }
                                                            />
                                                            <h3 className="text-lg font-semibold mb-1">
                                                                {
                                                                    activity.activity_name
                                                                }
                                                            </h3>
                                                            <div className="flex flex-wrap gap-1 mb-2">
                                                                <span className="bg-gray-200 text-gray-800 px-2 py-1 rounded-full text-xs">
                                                                    Grade:{" "}
                                                                    {
                                                                        activity.grade
                                                                    }
                                                                </span>
                                                                <span className="bg-gray-200 text-gray-800 px-2 py-1 rounded-full text-xs">
                                                                    Quarter:{" "}
                                                                    {
                                                                        activity.quarter
                                                                    }
                                                                </span>
                                                                <span className="bg-gray-200 text-gray-800 px-2 py-1 rounded-full text-xs">
                                                                    Chapter:{" "}
                                                                    {
                                                                        activity.chapter
                                                                    }
                                                                </span>
                                                            </div>
                                                            <p className="text-sm mb-2">
                                                                Concept:{" "}
                                                                {
                                                                    activity.concept
                                                                }
                                                            </p>
                                                        </div>

                                                        <div className="flex justify-center items-center mt-auto">
                                                            {cartItem ? (
                                                                <div className="flex items-center">
                                                                    <button
                                                                        onClick={() =>
                                                                            updateCart(
                                                                                activity,
                                                                                cartItem.quantity -
                                                                                    1
                                                                            )
                                                                        }
                                                                        className="border border-red-500 text-red-500 w-8 h-8 text-sm bg-transparent hover:bg-red-500 hover:text-white transition rounded-full"
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <span className="px-2 text-sm">
                                                                        {
                                                                            cartItem.quantity
                                                                        }
                                                                    </span>
                                                                    <button
                                                                        onClick={() =>
                                                                            updateCart(
                                                                                activity,
                                                                                cartItem.quantity +
                                                                                    1
                                                                            )
                                                                        }
                                                                        className="border border-green-500 text-green-500 w-8 h-8 text-sm bg-transparent hover:bg-green-500 hover:text-white transition rounded-full"
                                                                    >
                                                                        +
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                <button
                                                                    onClick={() =>
                                                                        updateCart(
                                                                            activity,
                                                                            1
                                                                        )
                                                                    }
                                                                    className="border border-blue-500 text-blue-500 px-3 py-1 rounded-lg text-sm bg-transparent hover:bg-blue-500 hover:text-white transition"
                                                                >
                                                                    Add to Cart
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            );
                        }
                    )}
                </div>

                {/* Image Popup */}
                {selectedImage && (
                    <div
                        className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                        onClick={closeImagePopup} // Close popup when clicking outside the image
                    >
                        <img
                            src={selectedImage}
                            alt="Enlarged"
                            className="max-w-screen-md max-h-screen-md object-contain" // Set max size for the image
                            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking on the image
                        />
                    </div>
                )}

                {/* Cart Component */}
                <div className="absolute top-4 right-4 flex flex-row gap-5">
                    <div className="md:hidden mb-4">
                        <button
                            className="border border-blue-500 text-blue-500 p-2 rounded-full bg-transparent hover:bg-blue-500 hover:text-white transition"
                            onClick={() => setIsFilterModalOpen(true)}
                            disabled={isCartOpen} // Disable when cart is open
                        >
                            <FaFilter />
                        </button>
                    </div>
                    <button
                        onClick={() => setIsCartOpen(!isCartOpen)} // Toggle cart popup
                        className={`relative border border-blue-500 text-blue-500 px-4 py-2 rounded-lg bg-transparent hover:bg-blue-500 hover:text-white transition ${
                            isCartOpen ? "opacity-50 cursor-not-allowed" : ""
                        }`} // Add styling for disabled state
                        disabled={isCartOpen} // Disable when cart is open
                    >
                        <FaShoppingCart className="text-xl" />
                        {uniqueItemCount > 0 && (
                            <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full px-2 text-xs">
                                {uniqueItemCount}
                            </span>
                        )}
                    </button>
                </div>

                {/* Cart Popup */}
                {isCartOpen && (
                    <div
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                        onClick={() => setIsCartOpen(false)} // Close on outside click
                    >
                        <div
                            className="relative bg-white p-8 rounded-lg shadow-lg w-[28rem] md:w-[32rem] lg:w-[36rem] max-h-[85vh] overflow-y-auto"
                            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
                        >
                            {/* Close button as an icon */}
                            <button
                                onClick={() => setIsCartOpen(false)}
                                className="absolute top-4 right-6 text-gray-500 text-2xl"
                            >
                                &times; {/* "X" icon (Unicode character) */}
                            </button>

                            <h2 className="text-2xl font-bold mb-6 text-center">
                                Cart
                            </h2>
                            {Object.keys(cart).length > 0 ? (
                                <ul className="space-y-6">
                                    {Object.values(cart).map(
                                        (cartItem: any) => (
                                            <li
                                                key={cartItem.uuid}
                                                className="flex justify-between items-center border-b pb-4"
                                            >
                                                <span className="text-lg font-semibold">
                                                    {cartItem.activity_name}
                                                </span>
                                                <div className="flex items-center space-x-2">
                                                    <button
                                                        onClick={() =>
                                                            updateCart(
                                                                cartItem,
                                                                cartItem.quantity -
                                                                    1
                                                            )
                                                        }
                                                        className="border border-red-500 text-red-500 w-8 h-8 flex items-center justify-center rounded-full hover:bg-red-500 hover:text-white transition"
                                                    >
                                                        -
                                                    </button>
                                                    <span className="px-2 text-lg">
                                                        {cartItem.quantity}
                                                    </span>
                                                    <button
                                                        onClick={() =>
                                                            updateCart(
                                                                cartItem,
                                                                cartItem.quantity +
                                                                    1
                                                            )
                                                        }
                                                        className="border border-green-500 text-green-500 w-8 h-8 flex items-center justify-center rounded-full hover:bg-green-500 hover:text-white transition"
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </li>
                                        )
                                    )}
                                </ul>
                            ) : (
                                <p className="text-center text-gray-500 my-4">
                                    Your cart is empty.
                                </p>
                            )}

                            {/* Footer with Clear and Submit buttons */}
                            <div className="mt-8 flex justify-between">
                                {Object.keys(cart).length > 0 && (
                                    <>
                                        <button
                                            onClick={clearCart}
                                            className="border border-red-800 text-red-800 px-6 py-2 rounded-lg bg-transparent hover:bg-red-800 hover:text-white transition"
                                        >
                                            Clear Cart
                                        </button>
                                        <button
                                            onClick={submitCart}
                                            className="border border-blue-500 text-blue-500 px-6 py-2 rounded-lg bg-transparent hover:bg-blue-500 hover:text-white transition"
                                        >
                                            Submit Cart
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Toaster />
        </div>
    );
}

export default Product;
