import { memo } from "react";
import type { FC } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import classes from "./App.module.css";
import resets from "./components/_resets.module.css";
import { Home } from "./components/Home/Home";
import Product from "./components/Product/Product";
import Login from "./components/Login/Login";
import SignUp from "./components/Signup/Signup";
import AdminPage from "./components/Admin/Admin";

interface Props {
    className?: string;
}
export const App: FC<Props> = memo(function App(props = {}) {
    return (
        <Router>
            {/* <div className={`${resets.clapyResets} ${classes.root}`}> */}
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/product" element={<Product />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/admin" element={<AdminPage />} />
                </Routes>
            {/* </div> */}
        </Router>
    );
});
